import { createLazyComponent, LazyModule } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "FavoriteContextMenu" */ './lazyIndex')
);

// Delay loaded components
export const FavoriteNodeContextMenu = createLazyComponent(
    lazyModule,
    m => m.FavoriteNodeContextMenu
);

export const FavoritesRootContextMenu = createLazyComponent(
    lazyModule,
    m => m.FavoritesRootContextMenu
);

export const PinFavoritesTeachingCallout = createLazyComponent(
    lazyModule,
    m => m.PinFavoritesTeachingCallout
);
