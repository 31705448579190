import { LazyModule, LazyAction } from 'owa-bundling-light';
export { ConvertIdSource } from './schema';

const lazyModule = new LazyModule(() => import(/* webpackChunkName: "OwaImmId" */ './lazyIndex'), {
    name: 'OwaImmId',
});

// declare a wrapper here that just calls through to importAndExecute,
// since we dont' want to change the calling convention for all
// immutable ID code.
const transparentLazyFn = <
    TModule,
    TWrappedAsyncFunctionArgs extends any[],
    TPromisedReturn extends any,
    TFunc extends (...args: TWrappedAsyncFunctionArgs) => Promise<TPromisedReturn>
>(
    m: LazyModule<TModule>,
    getter: (t: TModule) => TFunc
): TFunc => {
    const lazyAction = new LazyAction<TFunc, LazyModule<TModule>>(m, getter);
    return ((...args: TWrappedAsyncFunctionArgs): Promise<TPromisedReturn> => {
        return lazyAction.importAndExecute(...args);
        // This cast to the precise function type is required to preserve
        // overloads. Otherwise, we end up constructing a new function type
        // straight from the concrete signature, whcih discards the overloads.
    }) as TFunc;
};

/**
 * This function will convert the given IDs to the target format. The input IDs can be a mix of ConcatenatedIds, ImmutableIds and SMTP addresses.
 *
 * This function guarantees that the output array will have the same length as the input array, and will preserve the order of the input IDs.
 *
 * In case a given ID cannot be converted, **it will be left as is in the output array**.
 *
 * @see {@link tryConvertIdsToTargetFormat} for a version that removes IDs that cannot be converted.
 *
 * @param ids Array of IDs to convert
 * @param targetFormat Format the IDs should be converted to
 * @param userIdentity SMTP address of the user the IDs belong to
 * @param source Scenario triggering the translation
 * @param mailboxInfo Account the translation is being performed for
 */
export const convertIdsToTargetFormat = transparentLazyFn(
    lazyModule,
    m => m.convertIdsToTargetFormat
);
export const translateRestImmutableIdsToEwsIds = transparentLazyFn(
    lazyModule,
    m => m.translateRestImmutableIdsToEwsIds
);
export const translateEwsIdsToEwsImmutableIds = transparentLazyFn(
    lazyModule,
    m => m.translateEwsIdsToEwsImmutableIds
);
export const translateEwsImmutableIdsToEwsIds = transparentLazyFn(
    lazyModule,
    m => m.translateEwsImmutableIdsToEwsIds
);
export const translateRestIdsToRestImmutableIds = transparentLazyFn(
    lazyModule,
    m => m.translateRestIdsToRestImmutableIds
);

/**
 * Function to transform a given array of IDs to a target format.
 *
 * The input array can contain a mixture of ID types and the function will only convert the ones that need translation.
 *
 * This function **will remove** any ID that fails translation, thus the output array is not guaranteed to be the same lenght as the input array.
 * Relative order between input IDs is preserved in the output array.
 *
 * @param ids Array of IDs to convert
 * @param targetFormat Format to convert the IDs to, can be EwsId or EwsImmutableId
 * @param source Source of the ID translation scenario
 * @param mailboxInfo Account the translation is being performed for
 */
export const tryConvertIdsToTargetFormat = transparentLazyFn(
    lazyModule,
    m => m.tryConvertIdsToTargetFormat
);
