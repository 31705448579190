import type { ControlInfo, ReadOnlyRibbonControlDefProps } from 'owa-mail-ribbon-utils';
import { type MailRibbonControlId, type MailRibbonTabId } from 'owa-ribbon-ids/lib/mailRibbonId';
import {
    createMailAppButton,
    createMailAppFlyoutAnchor,
    createMailAppSplitButton,
} from 'owa-mail-ribbon-utils/lib/createMailRibbonButton';
import {
    onIgnoreStopIgnoreClicked,
    onNewEmailSplitButtonExecuted,
    onNewMessage,
    onNewNoteClicked,
    onNewPost,
} from 'owa-mail-ribbon-command-actions';
import { resetMoveToSearch } from 'owa-mail-ribbon-command-actions/lib/resetMoveToSearch';

import { type MenuItemType } from 'owa-filterable-menu/lib/components/MenuItemType';
import type { MenuOpenOnExecuteParameter } from '@1js/acui-common';
import type { RibbonControlProps } from '@1js/acui-ribbon-like/lib/UISurfaces/Ribbon/Controls/RibbonControlProps';
import { owaComputedFn } from 'owa-computed-fn';
import { constructKeytip } from 'owa-command-ribbon';
import { getComputedCallback } from 'owa-ribbon-mobx-utils/lib/getComputedCallback';
import { getControlInfo } from 'owa-mail-ribbon-utils/lib/getControlInfo';
import { getMailMenuItemShouldShow } from 'owa-mail-filterable-menu-behavior';
import { getMenuDefinition } from '../../../store/menuDefinitionStore';
import { getRibbonMailboxInfo } from 'owa-mail-ribbon-utils/lib/getRibbonMailboxInfo';
import { getSharedControlInfo } from 'owa-mail-ribbon-utils/lib/getSharedControlInfo';
import { isPublicFolderSelected } from 'owa-publicfolder-utils';
import { onMoveToMenuClicked } from '../../utils/deferredOnMoveToMenuClicked';
import { onNewGroupMenuClicked } from '../../utils/deferredOnNewGroupMenuClicked';
import { resetMenuDefinitionMutator } from '../../../mutatorActions/menuDefinitionMutatorActions';

/**
 * This file contains the definitions for all the controls
 * in the Multi Line Ribbon (MLR)
 */

export const getNewMessageControl = owaComputedFn(function getNewMessageControl(
    keytipTabId: MailRibbonTabId,
    props: ReadOnlyRibbonControlDefProps
): RibbonControlProps {
    const mailboxInfo = getRibbonMailboxInfo(props.mailboxInfo);
    const isNote: boolean = getMailMenuItemShouldShow(57, mailboxInfo);
    const isPublicFolder: boolean = isPublicFolderSelected();
    const controlInfo: ControlInfo = getControlInfo(588, props);
    const storeName = 'NewGroupMenuDefinitionStore';

    return createMailAppSplitButton(
        588,
        controlInfo.text,
        controlInfo.ariaText,
        controlInfo.tooltip,
        props,
        getMenuDefinition(storeName),
        isNote
            ? onNewNoteClicked
            : param => {
                  isPublicFolder
                      ? onNewPost(param?.event?.timeStamp)
                      : onNewMessage(param?.event?.timeStamp);
              },
        constructKeytip([keytipTabId], 588, true /* hasMenu */),
        controlInfo.iconName,
        undefined /*iconColor*/,
        undefined /*styles*/,
        undefined /*itemId*/,
        {
            onMenuButtonExecute: (parameter: MenuOpenOnExecuteParameter) => {
                onNewEmailSplitButtonExecuted(parameter);
                onNewGroupMenuClicked(keytipTabId, props);
            },
            onAfterMenuDismiss: () => {
                resetMenuDefinitionMutator(storeName);
            },
            primaryDisabled:
                !getMailMenuItemShouldShow(56, mailboxInfo) &&
                !getMailMenuItemShouldShow(57, mailboxInfo),
        }
    );
});

export const getIgnoreControl = owaComputedFn(function getIgnoreControl(
    props: ReadOnlyRibbonControlDefProps
): RibbonControlProps {
    const controlInfo: ControlInfo = getControlInfo(531, props);
    return createMailAppButton(
        531,
        controlInfo.text,
        controlInfo.tooltip,
        props,
        getComputedCallback(531, onIgnoreStopIgnoreClicked, props),
        constructKeytip([1], 531),
        controlInfo.iconName
    );
});

export const getMoveControl = owaComputedFn(function getMoveControl(
    props: ReadOnlyRibbonControlDefProps
): RibbonControlProps {
    const controlInfo: ControlInfo = getSharedControlInfo(540, props);
    const storeName = 'MoveToMenuDefinitionStore';

    return createMailAppFlyoutAnchor(
        540,
        controlInfo.text,
        controlInfo.tooltip,
        props,
        getMenuDefinition(storeName),
        constructKeytip([1], 540, true /* hasMenu */),
        controlInfo.iconName,
        undefined /*iconColor*/,
        undefined /*styles*/,
        undefined /*itemId*/,
        {
            delayUpdateFocusOnHover: true,
            onExecute: () => {
                resetMoveToSearch(true /*onEnter*/);
                onMoveToMenuClicked(1, props);
            },
            onAfterMenuDismiss: () => {
                resetMoveToSearch(false /* onEnter */);
                resetMenuDefinitionMutator(storeName);
            },
            shouldFocusOnMount: false, // To not override searchbox focus
            shouldFocusOnContainer: false, // To not override searchbox focus
            enableDownArrowNavigation: true, // to allow the searchbox lose its focus on the menu and enable the down arrow key navigation
        }
    );
});
