import { owaComputedFn } from 'owa-computed-fn';
import openInClientStore from 'owa-addins-view/lib/actions/openInClientStore';
import { createAppButton, constructKeytip } from 'owa-command-ribbon';
import { type ActionControlId } from 'owa-ribbon-ids/lib/actionControlId';
import { getComposeRibbonId } from 'owa-compose-ribbon-utils/lib/utils/getComposeRibbonId';
import { getHoverTooltip } from 'owa-compose-ribbon-utils/lib/utils/getHoverTooltip';
import AppsAddIn from 'owa-fluent-icons-svg/lib/icons/AppsAddInRegular';
import loc from 'owa-localize';
import { getAddIns_Button_ActionControl } from '../strings/actionControlStrings.locstring.json';
import { getAddinsDesc } from '../strings/hoverTooltipDesc.locstring.json';
import type { RibbonControlProps } from '@1js/acui-ribbon-like/lib/UISurfaces/Ribbon/Controls/RibbonControlProps';
import type { IButtonStyles } from '@fluentui/react';
import { getComputedCallback } from 'owa-ribbon-mobx-utils/lib/getComputedCallback';

export const getAddIns = owaComputedFn(
    (
        editorId: string | undefined,
        styles: IButtonStyles | undefined,
        keyTipTabId: number,
        isDisabled: boolean
    ): RibbonControlProps => {
        return createAppButton(
            getComposeRibbonId(529, editorId),
            loc(getAddIns_Button_ActionControl),
            getComputedCallback(529, openInClientStore),
            constructKeytip([keyTipTabId], 529),
            AppsAddIn,
            undefined /* iconColor */,
            styles,
            {
                customTooltip: getHoverTooltip(getAddIns_Button_ActionControl, getAddinsDesc),
                disabled: isDisabled,
            }
        );
    }
);
