import { SearchTab } from 'owa-search-types/lib/types/SearchTab';
import { isFeatureEnabled } from 'owa-feature-flags';
import { SearchScenarioId } from 'owa-search-store';
import { getApplicationSettings } from 'owa-application-settings';
import { isCopilotFeatureEnabled } from 'owa-copilot-settings-utils';
import { getModuleContextMailboxInfo } from 'owa-module-context-mailboxinfo';
import { isMailboxSharedOrDelegateUserMailbox } from 'owa-anchormailbox/lib/isMailboxSharedOrDelegateUserMailbox';
import isSharedFolderSearch from 'owa-mail-search-utils/lib/utils/isSharedFolderSearch';
import { getSafeSelectedTableView, getViewFilterForTable } from 'owa-mail-list-store';
import type { SearchTableQuery } from 'owa-mail-list-search';
import { getStore as getMailSearchStore } from 'owa-mail-search';
import { getSelectedNode } from 'owa-mail-folder-forest-store';
import { isReadingPanePositionRight } from 'owa-mail-layout';
import { type FolderForestNodeType } from 'owa-favorites-types';

export function isSearchChatEnabledForSession(scenarioId?: SearchScenarioId): boolean {
    const tableView = getSafeSelectedTableView();
    const tableQuery = tableView?.tableQuery;
    const activeSearchTab: SearchTab | undefined = tableQuery
        ? (tableQuery as SearchTableQuery).searchTab
        : undefined;
    const mailboxInfo = getModuleContextMailboxInfo();
    const searchProvider = getMailSearchStore().provider;
    const isInitialPersonaFavoriteFolder =
        getSelectedNode().type === 1 && getViewFilterForTable(tableView) == null;
    const isRPPositionRight = isReadingPanePositionRight();

    const isCopilotEnabledForMailbox = !!isCopilotFeatureEnabled(
        'Chat',
        mailboxInfo,
        getApplicationSettings('Copilot', mailboxInfo).enableMultiLanguage /** skipLanguageCheck */
    );
    const isSharedOrDelegateMailbox = isMailboxSharedOrDelegateUserMailbox(mailboxInfo);

    return (
        (scenarioId === SearchScenarioId.Mail || scenarioId === undefined) &&
        (activeSearchTab == undefined ||
            activeSearchTab == SearchTab.Mail ||
            activeSearchTab == SearchTab.All) &&
        isCopilotEnabledForMailbox &&
        !isSharedOrDelegateMailbox &&
        !isSharedFolderSearch() &&
        !isInitialPersonaFavoriteFolder &&
        isRPPositionRight &&
        !(mailboxInfo.type === 'ArchiveMailbox') &&
        (searchProvider === 'SubstrateSearchV2' || !searchProvider) &&
        (isFeatureEnabled('sea-searchPlusChat-noSERPUpdate') ||
            isFeatureEnabled('sea-searchPlusChat-suggestionsOnly-AppChatHandoff') ||
            isFeatureEnabled('sea-searchPlusChat-AppChatHandoff') ||
            isFeatureEnabled('sea-searchPlusChat-handoffAfterFirstTurn'))
    );
}
