import type { Capability } from 'owa-capabilities';
import type { MailboxInfo } from 'owa-client-types';
import { isServiceRequestSupportedForMailbox } from 'owa-service/lib/utils/isServiceRequestSupportedForMailbox';
import { isMailboxSharedOrDelegate } from 'owa-account-shared-mailbox-utils';
/**
 * NOTE: Please see the README for more information on how the
 * capability functions must be implemented and used.
 *
 * This capability is being used to determine if the outlook favorites service should be called
 */
export const favoritesRoamingCapability: Capability = {
    isEnabled: (_mailboxInfo?: MailboxInfo) => {
        return true; // by default
    },
    isSupported: (mailboxInfo?: MailboxInfo) => {
        if (mailboxInfo) {
            const isShared = isMailboxSharedOrDelegate(mailboxInfo);
            // In order to access the favorites service the logon must not be explicit, an explicit
            // logon indicates a shared mailbox which is exepcted to have multiple users,
            return !isShared && isServiceRequestSupportedForMailbox(mailboxInfo);
        }
        return false;
    },
};
