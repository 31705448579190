import { type MailRibbonControlId, type MailRibbonTabId } from 'owa-ribbon-ids/lib/mailRibbonId';
import { onLayoutMenuClicked } from '../../utils/deferredOnLayoutMenuClicked';
import { onMessagesMenuClicked } from '../../utils/deferredOnMessagesMenuClicked';

import type { ReadOnlyRibbonControlDefProps } from 'owa-mail-ribbon-utils';
import type { RibbonControlProps } from '@1js/acui-ribbon-like/lib/UISurfaces/Ribbon/Controls/RibbonControlProps';
import { owaComputedFn } from 'owa-computed-fn';
import { constructKeytip } from 'owa-command-ribbon';
import { createMailAppFlyoutAnchor } from 'owa-mail-ribbon-utils/lib/createMailRibbonButton';
import { getControlInfo } from 'owa-mail-ribbon-utils/lib/getControlInfo';
import { getDefaultSlrAppRibbonButtonStyles } from 'owa-command-ribbon-styles';
import { getMenuDefinition } from '../../../store/menuDefinitionStore';
import { resetMenuDefinitionMutator } from '../../../mutatorActions/menuDefinitionMutatorActions';

export const getLayoutControl = owaComputedFn(function getLayoutControl(
    props: ReadOnlyRibbonControlDefProps
): RibbonControlProps {
    const controlInfo = getControlInfo(646, props);
    const storeName = 'LayoutMenuDefinitionStore';
    return createMailAppFlyoutAnchor(
        646,
        controlInfo.text,
        controlInfo.tooltip,
        props,
        getMenuDefinition(storeName),
        constructKeytip([3], 646, true /* hasMenu */),
        controlInfo.iconName,
        undefined,
        getDefaultSlrAppRibbonButtonStyles(),
        undefined /* itemId */,
        {
            onExecute: () => {
                onLayoutMenuClicked(props);
            },
            onAfterMenuDismiss: () => {
                resetMenuDefinitionMutator(storeName);
            },
        }
    );
});

export const getMessagesControl = owaComputedFn(function getMessagesControl(
    props: ReadOnlyRibbonControlDefProps
): RibbonControlProps {
    const controlInfo = getControlInfo(654, props);
    const storeName = 'MessagesMenuDefinitionStore';
    return createMailAppFlyoutAnchor(
        654,
        controlInfo.text,
        controlInfo.tooltip,
        props,
        getMenuDefinition(storeName),
        constructKeytip([3], 654, true /* hasMenu */),
        controlInfo.iconName,
        undefined,
        getDefaultSlrAppRibbonButtonStyles(),
        undefined /* itemId */,
        {
            onExecute: () => {
                onMessagesMenuClicked(props);
            },
            onAfterMenuDismiss: () => {
                resetMenuDefinitionMutator(storeName);
            },
        }
    );
});
