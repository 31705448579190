import type { GroupFolderForestNode } from 'owa-favorites-types';
import { type FolderForestNodeType } from 'owa-favorites-types';
import { isFeatureEnabled } from 'owa-feature-flags';
import { getSxSViewState } from 'owa-groups-recent-files-store/lib/lazyFunctions';
import { getSharePointItemsViewCurrentPath } from 'owa-group-files-hub-store';
import { composeStore } from 'owa-mail-compose-store';
import { getSelectedNode } from 'owa-mail-folder-forest-store';
import { shouldShowReadingPane } from 'owa-mail-layout';
import getSelectedFolder from 'owa-mail-store/lib/utils/getSelectedFolder';
import { getCurrentOptionRoute } from 'owa-options-router';
import { isConsumer } from 'owa-session-store';
import folderIdToName from 'owa-session-store/lib/utils/folderIdToName';
import { getOrCreateSxSStoreData, getActiveSxSId } from 'owa-sxs-store';
import { PreviewPaneMode } from 'owa-sxsdata';
import {
    lazyGroupHeaderCommandBarStore,
    lazyGroupHeaderNavigationButton,
} from 'owa-group-header-actions';
import {
    getConversationReadingPaneViewState,
    getItemReadingPaneViewState,
} from 'owa-mail-reading-pane-store';
import { getGroupFolderWellKnownName } from 'owa-groups-shared-folders-store';
import { type GroupFolderWellKnownName } from 'owa-folders-constants';

export function getCurrentRoute() {
    const route: string[] = [];

    const optionRoute = getCurrentOptionRoute();

    if (optionRoute.length) {
        route.push(...optionRoute);
        return route;
    }

    if (composeStore.primaryComposeId) {
        route.push('compose');
        const composeViewState = composeStore.viewStates.get(composeStore.primaryComposeId);

        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2532 (42,13): Object is possibly 'undefined'.
        // @ts-expect-error
        if (composeViewState.itemId?.Id) {
            // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
            // -> Error TS2532 (46,24): Object is possibly 'undefined'.
            // @ts-expect-error
            route.push(composeViewState.itemId.Id);
        }

        return route;
    }

    const selectedNode = getSelectedNode();
    const sxsId: string | null = getActiveSxSId(window);
    const sxsStore = getOrCreateSxSStoreData(sxsId);

    if (selectedNode.type === 1) {
        if (selectedNode.id) {
            route.push('persons', selectedNode.id);
            return route;
        }
    } else if (selectedNode.type === 4) {
        if (selectedNode.id) {
            route.push('category', selectedNode.id);
            return route;
        }
    } else if (
        selectedNode.type === 2 ||
        (isFeatureEnabled('grp-loadFolders') && selectedNode.type === 6)
    ) {
        if (selectedNode.id) {
            let smtpAddress = null;
            switch (selectedNode.type) {
                case 2:
                    smtpAddress = selectedNode.id;
                    break;
                case 6:
                    smtpAddress = (selectedNode as GroupFolderForestNode).groupId;
                    break;
            }

            const emailAddressParts = smtpAddress.split('@');
            if (emailAddressParts.length != 2) {
                return route;
            }

            route.push(
                'group',
                // Domain part
                emailAddressParts[1],
                // alias part
                emailAddressParts[0]
            );

            /* eslint-disable-next-line no-restricted-properties -- (https://aka.ms/OWALintWiki)
             * BASELINE. Do not copy and paste!
             *	> 'tryImportForRender' is restricted from being used. tryImportForRender should only be used in rare circumstances and can hurt TTI if used incorrectly. */
            const groupHeaderCommandBarStore = lazyGroupHeaderCommandBarStore.tryImportForRender();
            const groupHeaderNavigationButton =
                /* eslint-disable-next-line no-restricted-properties -- (https://aka.ms/OWALintWiki)
                 * BASELINE. Do not copy and paste!
                 *	> 'tryImportForRender' is restricted from being used. tryImportForRender should only be used in rare circumstances and can hurt TTI if used incorrectly. */
                lazyGroupHeaderNavigationButton.tryImportForRender();

            if (groupHeaderCommandBarStore && groupHeaderNavigationButton) {
                switch (groupHeaderCommandBarStore.navigationButtonSelected) {
                    case groupHeaderNavigationButton.Email:
                        route.push('email');
                        if (selectedNode.type === 6) {
                            const wellKnownName = getGroupFolderWellKnownName(
                                smtpAddress,
                                selectedNode.id,
                                (selectedNode as GroupFolderForestNode).mailboxInfo
                            );
                            switch (wellKnownName) {
                                case 'inbox':
                                case 'deleteditems':
                                    route.push(wellKnownName);
                                    break;
                                default:
                                    route.push(selectedNode.id);
                            }
                        }
                        break;
                    case groupHeaderNavigationButton.Files:
                        route.push('files');

                        // SP file preview
                        const groupSxSStoreViewState = getSxSViewState();
                        if (
                            groupSxSStoreViewState?.previewPane &&
                            groupSxSStoreViewState.previewPane.mode != PreviewPaneMode.Blank
                        ) {
                            // Preview a SharePoint file using the new SxS
                            route.push('sxs', 'sp', groupSxSStoreViewState.previewPane.id);
                            break;
                        }

                        // attachment preview
                        if (sxsStore.extendedViewState.attachmentId && !isConsumer()) {
                            // The sxsStore.extendedViewState will never be null
                            route.push(
                                'sxs',
                                'attachment',
                                sxsStore.extendedViewState.attachmentId.Id
                            );
                            break;
                        }

                        // Items view navigation
                        if (getSharePointItemsViewCurrentPath) {
                            let path = getSharePointItemsViewCurrentPath(selectedNode.id);
                            if (path && path.length > 0) {
                                if (path.charAt(0) == '/') {
                                    path = path.substring(1);
                                }
                                const parts = path.split('/');
                                for (const p of parts) {
                                    route.push(p);
                                }
                            }
                        }

                        return route;
                }
            }
        }
    } else if (selectedNode.type === 3) {
        route.push('search');
    } else if (selectedNode.type === 0) {
        const selectedFolder = getSelectedFolder();

        if (selectedFolder) {
            // We push a friendly name if it is a distinguished folder (ex: inbox, junkemail, etc.),
            // otherwise we push its folder id.
            const selectedFolderId = selectedFolder.id;

            route.push(
                selectedFolder.distinguishedFolderType
                    ? folderIdToName(selectedFolderId)
                    : selectedFolderId
            );
        }
    }

    if (shouldShowReadingPane()) {
        const itemReadingPaneViewState = getItemReadingPaneViewState();
        const conversationReadingPaneViewState = getConversationReadingPaneViewState();
        if (conversationReadingPaneViewState) {
            route.push('id', conversationReadingPaneViewState.conversationId.Id);
        } else if (itemReadingPaneViewState) {
            route.push('id', itemReadingPaneViewState.itemId);
        }
    }

    if (sxsStore.extendedViewState.attachmentId) {
        route.push('sxs', sxsStore.extendedViewState.attachmentId.Id);
    }

    // if the route is the inbox, then return no route since it is the default
    if (route.length == 1 && route[0] == 'inbox') {
        return [];
    }
    return route;
}
