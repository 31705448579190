import { createStore } from 'owa-satcheljs';
import type SearchFolderState from './schema/SearchFolderViewState';

const searchFolderState: SearchFolderState = {
    createSearchFolderModalOpenState: new Map<string, boolean>(),
    createSearchFolderModalEntryPoint: undefined,
    areSearchFoldersLoaded: new Map<string, boolean>(),
    searchFolderEditState: {
        searchFolderId: '',
        searchFolderName: '',
        focusedClauseId: '',
        searchFolderType: undefined,
        searchFolderNameError: undefined,
        additionalData: null,
        isEditMode: false,
        inValidationState: false,
    },
};

const store = createStore<SearchFolderState>('searchFolderState', searchFolderState)();
export default store;
export const getStore = () => store;
