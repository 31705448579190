import { observer } from 'owa-mobx-react';
import { MailListFilterMenu } from 'owa-mail-list-filter-view';
import { MailListSortMenuV2 } from 'owa-mail-list-sort-view';
import { LazyMailListJumpToPopover, shouldShowJumpTo } from 'owa-mail-list-jumpto-view';
import {
    getStore as getListViewStore,
    getIsSearchTableShown,
    getUserCategoryName,
} from 'owa-mail-list-store';
import type { ActionSource } from 'owa-mail-store';
import React from 'react';
import type ViewFilter from 'owa-service/lib/contract/ViewFilter';
import { isFocusedInboxEnabled } from 'owa-mail-triage-common';
import folderIdToName from 'owa-session-store/lib/utils/folderIdToName';
import { getDensityModeCssClass } from 'owa-fabric-theme';
import { isSingleLineListView, isReadingPanePositionOff } from 'owa-mail-layout';
import type ReactListViewType from 'owa-service/lib/contract/ReactListViewType';
import { getIsNonMailEntitySearchTab } from 'owa-tabbed-search';
import { getMailboxInfoFromTableViewId } from 'owa-mail-mailboxinfo/lib/getMailboxInfo';
import { type MailListViewState } from 'owa-mail-store/lib/store/schema/MailListViewState';
import { getMailListLoadState } from 'owa-mail-list-store/lib/utils/getMailListLoadState';
import isSenderImageEnabled from 'owa-sender-image-option/lib/utils/isSenderImageEnabled';
import { isFeatureEnabled } from 'owa-feature-flags';
import {
    full,
    medium,
    compact,
    SLVItemViewSenderImageOn,
    SLVItemViewSenderImageOff,
    SLVConversationViewSenderImageOn,
    SLVConversationViewSenderImageOff,
    threeColItemViewSenderImageOn,
    threeColItemViewSenderImageOff,
    threeColConversationViewSenderImageOn,
    threeColConversationViewSenderImageOff,
    mailListSecondRowLeftEntity,
    selectAllCheckboxContainer,
    selectAllCheckboxContainerSearchTable,
    mailListHeaderContainer,
    mailListThirdRowMCL,
    mailListThirdRowSCL,
    mailListFirstRow,
    mailListSecondRow,
    mcl,
    scl,
    thirdrowPaddingBottom,
    withFirstRow,
    mailListSecondRowInnerContent,
    mailListSecondRowInnerContentSearchTable,
    notesHeaderSecondRow,
    stickyNotesHeaderPadding,
    mailListSecondRowCustomContainer,
    secondRowCustomContainer,
    selectAllHeaderStyle,
    conversationViewMCL,
    bulkActionBarPadding,
    checkboxSpaceReserved,
    senderImageOn,
    senderImageOff,
    showInboxPivots,
    noInboxPivots,
    headerIconContainer,
    headerIconContainerFull,
    headerIconContainerMediumOrCompact,
} from './MailListHeader.scss';

import type { UserSettingsStyleSelector } from '../utils/getStyleSelectorAsPerUserSettings';
import classnames from 'owa-classnames';
import SelectAllCheckbox from './SelectAllCheckbox';
import { LazyMailListHeaderIconContainer } from '../lazyFunctions';

export interface MailListHeaderProps {
    filterMenuSource: ActionSource;
    shouldShowFilterMenu: boolean;
    tableViewId: string;
    renderFirstRow: (mailListRowCss: string) => JSX.Element;
    renderSecondRowCustomContent: (customContainerCss: string) => JSX.Element;
    renderThirdRow: (mailListThirdRowCss: string) => JSX.Element;
    renderBulkActionProgressBar: () => JSX.Element;
    mailListHeaderStylesAsPerUserSettings?: UserSettingsStyleSelector;
}

//  Header Structure
// ---------------------------------------------------------------------------------------------------
// |  Mail List Header Container                                                                     |
// |  ---------------------------------------------------------------------------------------------  |
// |  | First Row (Custom - renders optionally)                                                   |  |
// |  |                                                                                           |  |
// |  ---------------------------------------------------------------------------------------------  |
// |  ---------------------------------------------------------------------------------------------  |
// |  | Second Row (Half Custom - renders always)                                                 |  |
// |  | ---------- -----------------------------                        --------------------------|  |
// |  | |  Check | |           Custom          |                        |     Filter Dropdown    ||  |
// |  | |   Box  | |           Content         |                        |    (based on scenario) ||  |
// |  | ---------- -----------------------------                        --------------------------|  |
// |  ---------------------------------------------------------------------------------------------  |
// |  ---------------------------------------------------------------------------------------------  |
// |  | Third Row (Custom - renders optionally)                                                   |  |
// |  |                                                                                           |  |
// |  ---------------------------------------------------------------------------------------------  |
// |  ---------------------------------------------------------------------------------------------  |
// |  | Bulk action progress bar (Custom - renders optionally)                                    |  |
// |  |                                                                                           |  |
// |  ---------------------------------------------------------------------------------------------  |
// ---------------------------------------------------------------------------------------------------

export default observer(function MailListHeader(props: MailListHeaderProps) {
    const mailboxInfo = getMailboxInfoFromTableViewId(props.tableViewId);
    const tableView = getListViewStore().tableViews.get(props.tableViewId);
    const isSingleLineView = isSingleLineListView();
    const isItemView = tableView?.tableQuery?.listViewType === 1;
    const isSearchTable = getIsSearchTableShown();
    const showMultiSelectButton = !isSearchTable;
    const densityModeString = getDensityModeCssClass(full, medium, compact);
    const isSelectionModeActivated = isSingleLineView
        ? tableView?.isInSelectAllStateSLV
        : tableView?.isInSelectAllState;
    const checkedStateMailList = isSelectionModeActivated || tableView?.isInCheckedMode;
    const showCheckboxForReselectFlight =
        isFeatureEnabled('tri-reselect-checkbox-behavior') &&
        (isSingleLineView || isSenderImageEnabled() || checkedStateMailList);
    const mailListViewState = getMailListLoadState();
    const isEmptyFolder = mailListViewState === 'Empty';
    const shouldShowSelectionHeader =
        isReadingPanePositionOff() &&
        ((tableView?.isInCheckedMode && (tableView?.selectedRowKeys.size ?? 0) > 0) ||
            tableView?.isInVirtualSelectAllMode);

    const categoryName = tableView ? getUserCategoryName(tableView) : null;
    const isCategoryFolder = categoryName !== null;

    const classNameForUserSettings =
        props.mailListHeaderStylesAsPerUserSettings &&
        (
            {
                SLVItemViewSenderImageOn,
                SLVItemViewSenderImageOff,
                SLVConversationViewSenderImageOn,
                SLVConversationViewSenderImageOff,
                threeColItemViewSenderImageOn,
                threeColItemViewSenderImageOff,
                threeColConversationViewSenderImageOn,
                threeColConversationViewSenderImageOff,
            } as const
        )[props.mailListHeaderStylesAsPerUserSettings];

    const renderSecondRowLeftEntity = (
        isInboxWithPivots: boolean,
        isStickyNotesHeader: boolean
    ) => {
        const shouldShowSelectAllCheckbox =
            showCheckboxForReselectFlight || !isFeatureEnabled('tri-reselect-checkbox-behavior');
        // Notes has no checkbox, while non-mail search tabs require a placeholder to avoid jumpy UI
        if (isStickyNotesHeader) {
            return null;
        } else if (getIsNonMailEntitySearchTab()) {
            return <div className={mailListSecondRowLeftEntity} />;
        } else if (!isEmptyFolder && shouldShowSelectAllCheckbox) {
            return (
                <div
                    className={classnames(
                        selectAllCheckboxContainer,
                        isSingleLineView ? mcl : scl,
                        isInboxWithPivots && !shouldShowSelectionHeader
                            ? showInboxPivots
                            : noInboxPivots,
                        densityModeString,
                        isSearchTable && selectAllCheckboxContainerSearchTable
                    )}
                >
                    <SelectAllCheckbox
                        tableViewId={props.tableViewId}
                        isInboxWithPivots={isInboxWithPivots}
                    />
                </div>
            );
        } else {
            if (isSearchTable) {
                return (
                    <div
                        className={classnames(
                            selectAllCheckboxContainer,
                            isSingleLineView ? mcl : scl,
                            isInboxWithPivots && !shouldShowSelectionHeader
                                ? showInboxPivots
                                : noInboxPivots,
                            densityModeString
                        )}
                    />
                );
            } else {
                return;
            }
        }
    };

    const renderMailListFilter = () => {
        const { filterMenuSource, tableViewId } = props;
        const supportedViewFilters = getViewFilters();
        return (
            <MailListFilterMenu
                filterMenuSource={filterMenuSource}
                supportedViewFilters={supportedViewFilters}
                tableViewId={tableViewId}
            />
        );
    };

    const mailListThirdRowCss = classnames(
        isSingleLineView ? mailListThirdRowMCL : mailListThirdRowSCL,
        classNameForUserSettings
    );
    const folderId = tableView?.tableQuery?.folderId ?? '';
    const isInboxWithPivots =
        isFocusedInboxEnabled(mailboxInfo) && 'inbox' === folderIdToName(folderId);
    const isStickyNotesHeader =
        folderIdToName(folderId) === 'notes' && isFeatureEnabled('notes-folder-view');
    const isLegacyNotesHeader =
        folderIdToName(folderId) === 'notes' && !isFeatureEnabled('notes-folder-view');
    if (!props.renderSecondRowCustomContent) {
        throw new Error('MailListHeader: renderSecondRowCustomContent should not be null');
    }
    const thirdRow = props.renderThirdRow?.(mailListThirdRowCss);
    const firstRow = props.renderFirstRow?.(mailListFirstRow);
    const isCheckboxSpaceReserved =
        isSearchTable ||
        checkedStateMailList ||
        isCategoryFolder ||
        isLegacyNotesHeader ||
        showCheckboxForReselectFlight;
    return (
        <div className={mailListHeaderContainer}>
            {props.renderFirstRow?.(mailListFirstRow)}
            <div
                className={classnames(
                    mailListSecondRow,
                    isSingleLineView ? mcl : scl,
                    isSenderImageEnabled() ? senderImageOn : senderImageOff,
                    isCheckboxSpaceReserved && checkboxSpaceReserved,
                    isInboxWithPivots ? showInboxPivots : noInboxPivots,
                    isStickyNotesHeader && stickyNotesHeaderPadding,
                    thirdRow && thirdrowPaddingBottom,
                    firstRow && withFirstRow,
                    densityModeString
                )}
            >
                <div
                    className={classnames(
                        mailListSecondRowInnerContent,
                        isSearchTable && mailListSecondRowInnerContentSearchTable
                    )}
                >
                    {((showMultiSelectButton &&
                        (checkedStateMailList || !props.shouldShowFilterMenu)) ||
                        showCheckboxForReselectFlight ||
                        !showMultiSelectButton) &&
                        renderSecondRowLeftEntity(isInboxWithPivots, isStickyNotesHeader)}
                    {((showMultiSelectButton && !checkedStateMailList) ||
                        isEmptyFolder ||
                        !showMultiSelectButton) &&
                        props.renderSecondRowCustomContent(
                            classnames(
                                isStickyNotesHeader
                                    ? notesHeaderSecondRow
                                    : mailListSecondRowCustomContainer,
                                classNameForUserSettings,
                                secondRowCustomContainer,
                                densityModeString
                            )
                        )}
                    {showMultiSelectButton &&
                        checkedStateMailList &&
                        !isEmptyFolder &&
                        props.renderSecondRowCustomContent(
                            classnames(
                                notesHeaderSecondRow,
                                selectAllHeaderStyle,
                                densityModeString
                            )
                        )}
                    {showMultiSelectButton && props.shouldShowFilterMenu && !isEmptyFolder && (
                        <LazyMailListHeaderIconContainer
                            tableView={tableView}
                            supportedViewFilters={getViewFilters()}
                            filterMenuSource={props.filterMenuSource}
                            tableViewId={props.tableViewId}
                        />
                    )}
                    {(!showMultiSelectButton || isEmptyFolder) &&
                        props.shouldShowFilterMenu &&
                        props.filterMenuSource !== 'GroupHeader' && (
                            <div
                                className={classnames(
                                    headerIconContainer,
                                    getDensityModeCssClass(
                                        headerIconContainerFull,
                                        headerIconContainerMediumOrCompact,
                                        headerIconContainerMediumOrCompact
                                    )
                                )}
                            >
                                {shouldShowJumpTo(tableView) && (
                                    <LazyMailListJumpToPopover tableViewId={props.tableViewId} />
                                )}
                                {renderMailListFilter()}
                                <MailListSortMenuV2
                                    sortMenuSource={props.filterMenuSource}
                                    tableViewId={props.tableViewId}
                                />
                            </div>
                        )}
                </div>
            </div>
            {thirdRow}
            <div
                className={classnames(
                    bulkActionBarPadding,
                    classNameForUserSettings,
                    isSingleLineView && !isItemView && conversationViewMCL
                )}
            >
                {props.renderBulkActionProgressBar?.()}
            </div>
        </div>
    );
}, 'MailListHeader');

function getViewFilters(): ViewFilter[] {
    const viewFilters: ViewFilter[] = [
        'All',
        'Unread',
        'Flagged',
        'ToOrCcMe',
        'HasFile',
        'Mentioned',
        'HasCalendarInvite',
    ];
    return viewFilters;
}
