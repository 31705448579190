import { isFeatureEnabled } from 'owa-feature-flags';
import { getModuleForTasks, ModuleForTasks } from 'owa-todo-module-utils';
import { M365AppId } from '../data/M365AppId';
import { getGlobalSettingsAccountMailboxInfo } from 'owa-account-source-list-store';
import type { MailboxInfo } from 'owa-client-types';
import { isToDoMetaOsEnabled } from '../utils/isToDoMetaOsEnabled';
import {
    isOneDriveMetaOsConsumerEnabled,
    isOneDriveMetaOsEnabled,
} from '../utils/isOneDriveMetaOsEnabled';

/**
 * Transforms the app bar tile id to the corresponding M365AppId.
 * @param coreAppId id of the app bar tile
 */
export function getM365AppIdFromPinAppId(coreAppId: string, mailboxInfo?: MailboxInfo): string {
    switch (coreAppId) {
        case 'Mail':
            return M365AppId.Mail;
        case 'Calendar':
            return M365AppId.Calendar;
        case 'People':
            return M365AppId.People;
        case 'ToDo':
            const tasksModule = getModuleForTasks(
                mailboxInfo ?? getGlobalSettingsAccountMailboxInfo()
            );
            if (tasksModule === ModuleForTasks.ToDo) {
                if (isToDoMetaOsEnabled(mailboxInfo)) {
                    return M365AppId.ToDoMetaOs;
                }
                return M365AppId.ToDo;
            }
            return M365AppId.Tasks;
        case 'Word':
            return M365AppId.Word;
        case 'Excel':
            return M365AppId.Excel;
        case 'PowerPoint':
            return M365AppId.PowerPoint;
        case 'OneNote':
            return M365AppId.OneNote;
        case 'OneDrive':
            return isOneDriveMetaOsConsumerEnabled(mailboxInfo)
                ? M365AppId.OneDriveConsumerMetaOs
                : isOneDriveMetaOsEnabled(mailboxInfo)
                ? M365AppId.OneDriveMetaOs
                : M365AppId.OneDrive;
        case '9b049ed6-fc8b-40c7-815b-bf84e344d270': // Old org explorer app id
            return M365AppId.OrgExplorer;
        case M365AppId.EventsProd:
            if (
                /* eslint-disable-next-line owa-custom-rules/require-undefined-parameter -- (https://aka.ms/OWALintWiki)
                 * Flight checks that supply MailboxInfo should be defined as AccountFeatureName value and should be checked using isAccountFeatureEnabled to ensure consistent checking.
                 *	> The parameter mailboxInfo must be undefined. Feature flight: 'mos-eventsApp' */
                isFeatureEnabled('mos-eventsApp', mailboxInfo) &&
                /* eslint-disable-next-line owa-custom-rules/require-undefined-parameter -- (https://aka.ms/OWALintWiki)
                 * Flight checks that supply MailboxInfo should be defined as AccountFeatureName value and should be checked using isAccountFeatureEnabled to ensure consistent checking.
                 *	> The parameter mailboxInfo must be undefined. Feature flight: 'mos-eventsApp-prod' */
                !isFeatureEnabled('mos-eventsApp-prod', mailboxInfo)
            ) {
                return M365AppId.EventsSdf;
            } else {
                return M365AppId.EventsProd;
            }
        case 'Groups':
            return M365AppId.Groups;
        default:
            return coreAppId;
    }
}
