import getStore from '../store/store';
import { isFeatureEnabled } from 'owa-feature-flags';
import type { MailboxInfo } from 'owa-client-types';
import { getUserConfiguration } from 'owa-session-store';
import { isSharedCoprincipalAccountEnabled } from 'owa-anchormailbox/lib/isSharedCoprincipalAccountEnabled';
import { isMailboxSharedOrDelegateUserMailbox } from 'owa-anchormailbox/lib/isMailboxSharedOrDelegateUserMailbox';

export const getIsCustomSortEnabled = (mailboxInfo: MailboxInfo) => {
    if (isSharedCoprincipalAccountEnabled() && isMailboxSharedOrDelegateUserMailbox(mailboxInfo)) {
        // Custom sorting is not supported for shared coprincipal accounts
        return false;
    }

    const userConfiguration = getUserConfiguration();
    const isExplicitLogon = userConfiguration?.SessionSettings?.IsExplicitLogon;
    return (
        !isExplicitLogon &&
        /* eslint-disable-next-line owa-custom-rules/require-undefined-parameter -- (https://aka.ms/OWALintWiki)
         * Flight checks that supply MailboxInfo should be defined as AccountFeatureName value and should be checked using isAccountFeatureEnabled to ensure consistent checking.
         *	> The parameter mailboxInfo must be undefined. Feature flight: 'fwk-folders-custom-sorted-on-boot' */
        isFeatureEnabled('fwk-folders-custom-sorted-on-boot', mailboxInfo) &&
        !getStore().isAlphabetizeFolderList
    );
};
