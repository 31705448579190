import { createStore } from 'owa-satcheljs';
import type { ModuleContextMailboxInfoData } from './schema/ModuleContextMailboxInfoData';
import type { Module } from 'owa-workloads';
import type { MailboxInfo } from 'owa-client-types';

const defaultStore: ModuleContextMailboxInfoData = {
    moduleContextMailboxInfos: new Map<Module, MailboxInfo>(),
};

export default createStore<ModuleContextMailboxInfoData>(
    'ModuleContextMailboxInfoData',
    defaultStore
);
