// extracted by mini-css-extract-plugin
export var allPaneContainer = "FJ4hV";
export var fullBleed = "WKBCQ";
export var innerPaneContent = "SW7A6";
export var leftColumn = "YYA9V";
export var leftColumnBleedThrough = "GciPK";
export var leftPaneContainer = "Yd48r";
export var leftPanelSecondRow = "kTUsm";
export var leftResizeHandler = "lKhXQ";
export var mailListHide = "c_ifE";
export var mailListLeftPaneVisibleFullBleed = "hmUtu";
export var mailListRefresh = "LyMTI";
export var mailListWithRPHiddenOrBottom = "XW2m0";
export var mailListWithRPRight = "C1EJK";
export var mailMain = "I3sS5";
export var newSearchHeaderContainer = "Dt4E5";
export var newSearchHeaderContainerMargin = "SCAqn";
export var panes = "LBktY";
export var positionRelative = "U5nOs";
export var protocolNotSupportedContainer = "dbelr";
export var readingPane = "Mq3cC";
export var rightPane = "HOVUa";
export var rightPaneContainer = "q9iRC";
export var rpPositionBottom = "bYKNI";
export var searchHeaderBorders = "brBxj";
export var searchResultsContainer = "PsJx7";
export var searchResultsTabbedContainer = "RhuSi";
export var subCommandBarPaneColumn = "G8_Dc";
export var subCommandBarPaneCommon = "FkPdL";
export var subCommandBarPaneCommonRefresh = "czwRD";
export var subCommandBarPaneMail = "LCprM";
export var subCommandBarPaneRow = "nbmyu";