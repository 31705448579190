import {
    type SupportedScenario,
    ElaborateCapabilitiesType,
    RewriteCapabilitiesType,
    TimeCopilotChatCapabilitiesType,
    InboxPrioritizationScenarios,
} from '../types/serviceTypes';
import { Scenario } from '../types/serviceSettingTypes';
import { isImplementedScenario } from '../../utils/isImplementedScenario';
import {
    type CopilotFeatureCapabilities,
    type FeatureInfo,
    type ImplementedCopilotFeatures,
} from '../../store/store';

export function createFeatureInfoMap(scenarios: SupportedScenario[]) {
    const featureInfoMap = new Map<ImplementedCopilotFeatures, FeatureInfo>();
    scenarios.forEach(scenario => {
        const implementedScenario = isImplementedScenario(scenario.scenario);
        if (!!implementedScenario) {
            featureInfoMap.set(implementedScenario, {
                isEnabled: true,
                langauges: scenario.supportedLanguages,
                capabilities: mapCapabilitiesFromScenario(scenario),
                optInEnabled: scenario.optInSettings?.enabled ?? false,
            });
        }
    });
    return featureInfoMap;
}

function mapCapabilitiesFromScenario(
    scenario: SupportedScenario
): CopilotFeatureCapabilities | undefined {
    switch (scenario.scenario) {
        case Scenario.Elaborate:
            return {
                tonePersonalization:
                    scenario.capabilities.indexOf(ElaborateCapabilitiesType.TonePersonalization) >=
                    0,
                calendarElaborate:
                    scenario.capabilities.indexOf(ElaborateCapabilitiesType.CalendarElaborate) >= 0,
            };
        case Scenario.Rewrite:
            return {
                highlightAndRewrite:
                    scenario.capabilities.indexOf(RewriteCapabilitiesType.HighlightAndRewrite) >= 0,
                calendarRewrite:
                    scenario.capabilities.indexOf(RewriteCapabilitiesType.CalendarRewrite) >= 0,
                autoRewriteStaticPrompt:
                    scenario.capabilities.indexOf(
                        RewriteCapabilitiesType.AutoRewriteStaticPrompt
                    ) >= 0,
            };
        case Scenario.TimeCopilotChat:
            return {
                triage: scenario.capabilities.indexOf(TimeCopilotChatCapabilitiesType.Triage) >= 0,
                schedulingCompactCard:
                    scenario.capabilities.indexOf(
                        TimeCopilotChatCapabilitiesType.SchedulingCompactCard
                    ) >= 0,
                workHoursLocation:
                    scenario.capabilities.indexOf(
                        TimeCopilotChatCapabilitiesType.WorkHoursLocation
                    ) >= 0,
                speedyMeetings:
                    scenario.capabilities.indexOf(TimeCopilotChatCapabilitiesType.SpeedyMeetings) >=
                    0,
                darkMode:
                    scenario.capabilities.indexOf(TimeCopilotChatCapabilitiesType.DarkMode) >= 0,
                categorizationUpdates:
                    scenario.capabilities.indexOf(
                        TimeCopilotChatCapabilitiesType.CategorizationUpdates
                    ) >= 0,
                calTimeSuggestionsV2:
                    scenario.capabilities.indexOf(
                        TimeCopilotChatCapabilitiesType.CalTimeSuggestionsV2
                    ) >= 0,
                monCopilotChatContextIq:
                    scenario.capabilities.indexOf(
                        TimeCopilotChatCapabilitiesType.MonCopilotChatContextIq
                    ) >= 0,
            };
        case Scenario.InboxCatchup:
            return {
                backgroundProcessing:
                    scenario.capabilities &&
                    scenario.capabilities.indexOf(
                        InboxPrioritizationScenarios.BackgroundProcessing
                    ) >= 0,
            };
        default:
            return undefined;
    }
}
