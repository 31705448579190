import { getLogicalRing } from 'owa-config/lib/getLogicalRing';
import { isFeatureEnabled } from 'owa-feature-flags';

import type { MailboxInfo } from 'owa-client-types';

/**
 * Places MetaOS app is enabled if user is in dogfood and places-web-app-enabled flight is enabled.
 */
export function isMosPlacesEnabledForSdf(mailboxInfo?: MailboxInfo) {
    const { isFlightEnabled, isDogfood } = getParameters(mailboxInfo);
    return isFlightEnabled && isDogfood;
}

/**
 * Places MetaOS app is enabled if user places-web-app-enabled flight is enabled.
 */
export function isMosPlacesEnabledForProd(mailboxInfo?: MailboxInfo) {
    const { isFlightEnabled } = getParameters(mailboxInfo);
    return isFlightEnabled;
}

function getParameters(mailboxInfo?: MailboxInfo) {
    return {
        isFlightEnabled:
            /* eslint-disable-next-line owa-custom-rules/require-undefined-parameter -- (https://aka.ms/OWALintWiki)
             * Flight checks that supply MailboxInfo should be defined as AccountFeatureName value and should be checked using isAccountFeatureEnabled to ensure consistent checking.
             *	> The parameter mailboxInfo must be undefined. Feature flight: 'places-web-app-enabled' */
            isFeatureEnabled('places-web-app-enabled', mailboxInfo) &&
            /* eslint-disable-next-line owa-custom-rules/require-undefined-parameter -- (https://aka.ms/OWALintWiki)
             * Flight checks that supply MailboxInfo should be defined as AccountFeatureName value and should be checked using isAccountFeatureEnabled to ensure consistent checking.
             *	> The parameter mailboxInfo must be undefined. Feature flight: 'places-mos-owa-enabled' */
            isFeatureEnabled('places-mos-owa-enabled', mailboxInfo),
        isDogfood: getLogicalRing() === 'Dogfood',
    };
}
