import DOMPurify from 'dompurify';
import type { Config } from 'dompurify';

let owaTrustedTypesPolicy: Pick<TrustedTypePolicy, 'createHTML' | 'createScriptURL'> | undefined;
// Regex to match either /mail/sw.js or /mail/<any string>/sw.js or /mail/<any string>/<any string>/sw.js
const regex = /^\/mail\/([^\/]+\/)*sw\.js/;
// Regex to match either /<any string>/<any string>/sw_webpush.js or /sw_<any string>_webpush.js?branch=<any string>
// Includes OWA mini `{prefix}/sw_mini_webpush.js` as defined by router in Microsoft.Owa.Web.Server/Startup.cs
const webpushRegex =
    /^\/([^\/]+\/)*sw(_[^\/_]+)*_webpush\.js(\?(branch|ring|version)=[^\/_=]+){0,1}$/;

if (window.trustedTypes) {
    owaTrustedTypesPolicy = window.trustedTypes.createPolicy('owaTrustedTypesPolicy', {
        createHTML: (html: string, extendedConfig: Config): string => {
            const trustedHTML = DOMPurify.sanitize(html, {
                ...extendedConfig,
                RETURN_TRUSTED_TYPE: false,
            });

            return trustedHTML?.toString() ?? '';
        },
        createScriptURL: (url: string): string => {
            if (regex.test(url) || webpushRegex.test(url)) {
                return url;
            }

            // The URL is not meeting the conditions for a safe URL so return empty string
            return '';
        },
    });
}

export { owaTrustedTypesPolicy, regex, webpushRegex };
