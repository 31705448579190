import { createStore } from 'owa-satcheljs';

// Define the store interface
interface FolderNavigationStore {
    message: string | undefined;
}

// Create the satchel store
const store = createStore<FolderNavigationStore>('folderNavigationStore', {
    message: undefined,
})();

// Define the getters and setters
const setMessage = (message: string | undefined) => {
    store.message = message;
};

const getMessage = () => {
    return store.message;
};

export { setMessage, getMessage };
