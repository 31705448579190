import DOMPurify from 'dompurify';
import type { Config } from 'dompurify';
import { owaTrustedTypesPolicy } from '../trustedTypesPolicies/owaTrustedTypesPolicy';
import getGlobalSanitizerConfig from './getGlobalSanitizerConfig';
import LRUCache from './LRUCache';

enum SanitizerReturnType {
    String,
    HTMLElement,
    DocumentFragment,
    Node,
}

/**
 * This number can increase if we see more cases of HTML sanitizing.
 */
const cache = new LRUCache<string>(50);

// Function overloads
function getTrustedHTML(htmlString: string, returnType: SanitizerReturnType.String): string;
function getTrustedHTML(
    htmlString: string,
    returnType: SanitizerReturnType.HTMLElement
): HTMLElement;
function getTrustedHTML(
    htmlString: string,
    returnType: SanitizerReturnType.DocumentFragment
): DocumentFragment;
function getTrustedHTML(htmlString: string, returnType: SanitizerReturnType.Node): Node;
function getTrustedHTML(
    htmlString: string,
    returnType: SanitizerReturnType.String,
    extendedConfig: Config
): string;
function getTrustedHTML(
    htmlString: string,
    returnType: SanitizerReturnType.HTMLElement,
    extendedConfig: Config
): HTMLElement;
function getTrustedHTML(
    htmlString: string,
    returnType: SanitizerReturnType.DocumentFragment,
    extendedConfig: Config
): DocumentFragment;
function getTrustedHTML(
    htmlString: string,
    returnType: SanitizerReturnType.Node,
    extendedConfig: Config
): Node;

function getTrustedHTML(
    htmlString: string,
    returnType: SanitizerReturnType,
    extendedConfig: Config = {}
): string | HTMLElement | DocumentFragment | Node {
    let trustedHTML: string | HTMLElement | DocumentFragment | Node = '';

    if (returnType === SanitizerReturnType.String) {
        // Create a unique cache key based on the HTML string and the serialized configuration
        const cacheKey = `${htmlString}:${JSON.stringify(extendedConfig)}`;
        // Check if the sanitized html string is in the cache
        const cachedValue = cache.get(cacheKey);

        if (cachedValue) {
            return cachedValue;
        }

        // Merge the default config with the extended config
        extendedConfig = {
            ...getGlobalSanitizerConfig(extendedConfig),
        };

        trustedHTML = owaTrustedTypesPolicy
            ? (owaTrustedTypesPolicy.createHTML(htmlString, extendedConfig) as unknown as string)
            : DOMPurify.sanitize(htmlString, extendedConfig).toString();

        // Store the sanitized HTML string in the cache
        cache.set(cacheKey, trustedHTML);
    } else if (returnType === SanitizerReturnType.HTMLElement) {
        trustedHTML = DOMPurify.sanitize(htmlString, {
            ...getGlobalSanitizerConfig(extendedConfig),
            RETURN_DOM: true,
        }) as HTMLElement;
    } else if (returnType === SanitizerReturnType.DocumentFragment) {
        trustedHTML = DOMPurify.sanitize(htmlString, {
            ...getGlobalSanitizerConfig(extendedConfig),
            RETURN_DOM_FRAGMENT: true,
        });
    } else if (returnType === SanitizerReturnType.Node) {
        trustedHTML = DOMPurify.sanitize(htmlString, {
            ...getGlobalSanitizerConfig(extendedConfig),
            RETURN_DOM: true,
        });
    }

    // Return the sanitized HTML string
    return trustedHTML;
}

export { getTrustedHTML, SanitizerReturnType };
