import type PopoutParentStore from './schema/PopoutParentStore';
import { createStore } from 'owa-satcheljs';

const parentStoreData: PopoutParentStore = {
    projections: [],
    isAvailable: false,
};

const parentStore = createStore<PopoutParentStore>('popoutParent', parentStoreData)();
export default parentStore;
export const getStore = () => parentStore;
