import React from 'react';
import { errorThatWillCauseAlert } from 'owa-trace';

import {
    getDefaultIconPreviewGalleryRenderer,
    getDefaultRibbonButtonRenderer,
    getDefaultRibbonFlyoutAnchorRenderer,
    getDefaultSplitButtonRenderer,
} from '@1js/acui-ribbon-like/lib/UISurfaces/Ribbon/ControlRenderers';
import {
    lazyGetAppCheckBoxControlRenderer,
    lazyGetAppComboBoxControlRenderer,
    lazyGetAppToggleButtonControlRenderer,
} from '../lazyFunctions';

import type { AppCheckboxProps } from '@1js/acui-checkbox/lib/components/AppCheckbox/AppCheckbox.Props';
import type { AppComboBoxProps } from '@1js/acui-combobox/lib/components/AppComboBox/AppComboBox.Props';
import type { AppControlUnionProps } from '@1js/acui-ribbon-like/lib/UISurfaces/controls/AppControlProps';
import type { AppToggleButtonProps } from '@1js/acui-button/lib/components/AppToggleButton/AppToggleButton.Props';
import type { ControlInMenuRendererFunction } from '@1js/acui-menu/lib/ControlInMenuRendererFunction';
import type { ControlRendererFunction } from '@1js/acui-ribbon-like/lib/UISurfaces/controls/controlRendererFunctionTypes';
import { appIcons } from 'owa-acui-theme';
import { memoizeFunction } from '@fluentui/react/lib/Utilities';

const getEmptyControlRendererFunction = () => () => <></>;

/**
 * This function should contain the minimal set of control renderers for only the buttons
 * that show up on Ribbon surface.
 * Nothing in the dropdowns, just any type of control that the user could see on boot.
 */
export const getMailControlRendererFunction = memoizeFunction(
    (controlInMenuRendererFunction: ControlInMenuRendererFunction): ControlRendererFunction => {
        return (props: AppControlUnionProps) => {
            const { shouldTakeFocus = true, type } = props;

            switch (type) {
                case 'AppButtonProps':
                    return getDefaultRibbonButtonRenderer()({
                        ...props,
                        shouldTakeFocus,
                    });
                case 'AppComboBoxProps': {
                    /* eslint-disable-next-line no-restricted-properties -- (https://aka.ms/OWALintWiki)
                     * BASELINE. Do not copy and paste!
                     *	> 'tryImportForRender' is restricted from being used. tryImportForRender should only be used in rare circumstances and can hurt TTI if used incorrectly. */
                    const getRenderer = lazyGetAppComboBoxControlRenderer.tryImportForRender();
                    const rendererFunction = getRenderer
                        ? getRenderer
                        : getEmptyControlRendererFunction;
                    return rendererFunction(appIcons)(props as AppComboBoxProps);
                }
                case 'AppToggleButtonProps': {
                    /* eslint-disable-next-line no-restricted-properties -- (https://aka.ms/OWALintWiki)
                     * BASELINE. Do not copy and paste!
                     *	> 'tryImportForRender' is restricted from being used. tryImportForRender should only be used in rare circumstances and can hurt TTI if used incorrectly. */
                    const getRenderer = lazyGetAppToggleButtonControlRenderer.tryImportForRender();
                    const rendererFunction = getRenderer
                        ? getRenderer
                        : getEmptyControlRendererFunction;
                    return rendererFunction()(props as AppToggleButtonProps);
                }
                case 'AppCheckboxProps': {
                    /* eslint-disable-next-line no-restricted-properties -- (https://aka.ms/OWALintWiki)
                     * BASELINE. Do not copy and paste!
                     *	> 'tryImportForRender' is restricted from being used. tryImportForRender should only be used in rare circumstances and can hurt TTI if used incorrectly. */
                    const getRenderer = lazyGetAppCheckBoxControlRenderer.tryImportForRender();
                    const rendererFunction = getRenderer
                        ? getRenderer
                        : getEmptyControlRendererFunction;
                    return rendererFunction(appIcons)(props as AppCheckboxProps);
                }
                case 'AppFlyoutAnchorProps':
                    return getDefaultRibbonFlyoutAnchorRenderer(
                        appIcons,
                        controlInMenuRendererFunction
                    )(props);
                case 'AppSplitButtonProps':
                    return getDefaultSplitButtonRenderer(
                        appIcons,
                        controlInMenuRendererFunction
                    )({
                        ...props,
                        shouldTakeFocus,
                    });
                case 'AppIconPreviewGalleryProps':
                    return getDefaultIconPreviewGalleryRenderer(
                        appIcons,
                        controlInMenuRendererFunction
                    )({ menuLauncherLabel: '', ...props });

                default:
                    /* eslint-disable-next-line owa-custom-rules/no-error-dynamic-event-names -- (https://aka.ms/OWALintWiki)
                     * The error name (message) must be a string literal (no variables in it).
                     *	> Error names can only be a string literals. Use the diagnosticInfo to add custom data. */
                    errorThatWillCauseAlert(
                        'getMailControlRendererFunction: Unknown control type: ' +
                            type +
                            '. This means this function is missing a control renderer for that specific control.'
                    );
                    return <></>;
            }
        };
    }
);
