import { isOptInExperienceEnabledForScenario } from 'owa-copilot-settings-store';
import type { MailboxInfo } from 'owa-client-types';
import { isFeatureEnabled } from 'owa-feature-flags';

export default function isOptInExperienceEnabledForPrioritize(mailboxInfo: MailboxInfo) {
    if (!isFeatureEnabled('mon-copilot-prioritize-command-center-dev')) {
        return false;
    }

    return isOptInExperienceEnabledForScenario('Inbox', mailboxInfo);
}
