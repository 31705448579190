import type {
    KeyboardShortcutsMode,
    OnlineMeetingProvider,
    RetentionAction,
    ClientTypeOptInState as Schema_ClientTypeOptInState,
    DisposableEmailAddress as Schema_DisposableEmailAddress,
    DisposableEmailAddressState as Schema_DisposableEmailAddressState,
    ElcFolderType as Schema_ElcFolderType,
    FirstWeekOfYear as Schema_FirstWeekOfYear,
    GroupsSetType as Schema_GroupsSetType,
    LocalEventsEnabledStatus as Schema_LocalEventsEnabledStatus,
    MailSwipeAction as Schema_MailSwipeAction,
    MailTriageAction as Schema_MailTriageAction,
    NewNotification as Schema_NewNotification,
    ReactListViewType as Schema_ReactListViewType,
    RetentionPolicyTag as Schema_RetentionPolicyTag,
    SessionSettings as Schema_SessionSettings,
    TemperatureUnit as Schema_TemperatureUnit,
    UnifiedGroup as Schema_UnifiedGroup,
    UnifiedGroupAccessType as Schema_UnifiedGroupAccessType,
    UnifiedGroupExpiryStatus as Schema_UnifiedGroupExpiryStatus,
    UnifiedGroupsFilterType as Schema_UnifiedGroupsFilterType,
    UnifiedGroupsSet as Schema_UnifiedGroupsSet,
    UnifiedGroupsSets as Schema_UnifiedGroupsSets,
    UserOptions as Schema_UserOptions,
    ViewStateConfiguration as Schema_ViewStateConfiguration,
    WeatherEnabledStatus as Schema_WeatherEnabledStatus,
    WebSessionType as Schema_WebSessionType,
    UserConfiguration,
} from 'owa-graph-schema';
import type ClientTypeOptInState from 'owa-service/lib/contract/ClientTypeOptInState';
import type DisposableEmailAddress from 'owa-service/lib/contract/DisposableEmailAddress';
import type DisposableEmailAddressState from 'owa-service/lib/contract/DisposableEmailAddressState';
import type ElcFolderType from 'owa-service/lib/contract/ElcFolderType';
import type GroupsSetType from 'owa-service/lib/contract/GroupsSetType';
import { type default as WebKeyboardShortcutsMode } from 'owa-service/lib/contract/KeyboardShortcutsMode';
import type LocalEventsEnabledStatus from 'owa-service/lib/contract/LocalEventsEnabledStatus';
import type MailSwipeAction from 'owa-service/lib/contract/MailSwipeAction';
import type MailTriageAction from 'owa-service/lib/contract/MailTriageAction';
import type NewNotification from 'owa-service/lib/contract/NewNotification';
import type OnlineMeetingProviderType from 'owa-service/lib/contract/OnlineMeetingProviderType';
import type OwaUserConfiguration from 'owa-service/lib/contract/OwaUserConfiguration';
import type ViewStateConfiguration from 'owa-service/lib/contract/OwaViewStateConfiguration';
import type ReactListViewType from 'owa-service/lib/contract/ReactListViewType';
import type RetentionActionType from 'owa-service/lib/contract/RetentionActionType';
import type RetentionPolicyTag from 'owa-service/lib/contract/RetentionPolicyTag';
import type SessionSettings from 'owa-service/lib/contract/SessionSettingsType';
import type TemperatureUnit from 'owa-service/lib/contract/TemperatureUnit';
import type UnifiedGroup from 'owa-service/lib/contract/UnifiedGroup';
import type UnifiedGroupAccessType from 'owa-service/lib/contract/UnifiedGroupAccessType';
import type UnifiedGroupExpiryStatus from 'owa-service/lib/contract/UnifiedGroupExpiryStatus';
import type UnifiedGroupsFilterType from 'owa-service/lib/contract/UnifiedGroupsFilterType';
import type UnifiedGroupsSet from 'owa-service/lib/contract/UnifiedGroupsSet';
import type UnifiedGroupsSets from 'owa-service/lib/contract/UnifiedGroupsSetsType';
import type UserOptions from 'owa-service/lib/contract/UserOptionsType';
import type WeatherEnabledStatus from 'owa-service/lib/contract/WeatherEnabledStatus';
import type WeatherTemperatureUnit from 'owa-service/lib/contract/WeatherTemperatureUnit';
import type WebSessionType from 'owa-service/lib/contract/WebSessionType';
import { FirstWeekOfYear } from './FirstWeekOfYear';
import { type MailboxInfo, getAccountKeyForMailboxInfo } from 'owa-client-types';

const unifiedGroupAccessType = (
    type?: UnifiedGroupAccessType
): Schema_UnifiedGroupAccessType | null => {
    if (type == undefined) {
        return null;
    }

    switch (type) {
        case 0:
            return 'None';
        case 1:
            return 'Private';
        case 2:
            return 'Secret';
        case 3:
            return 'Public';
    }
};

const unifiedGroupExpiryStatus = (
    status?: UnifiedGroupExpiryStatus
): Schema_UnifiedGroupExpiryStatus | null => {
    if (status == undefined) {
        return null;
    }

    switch (status) {
        case 0:
            return 'NotExpiring';
        case 1:
            return 'Expiring';
        case 2:
            return 'Expired';
        case 3:
            return 'NotEligible';
    }
};

const disposableEmailAddressState = (
    state?: DisposableEmailAddressState
): Schema_DisposableEmailAddressState | null => {
    if (state === undefined) {
        return null;
    }

    switch (state) {
        case 0:
            return 'Unprovisioned';
        case 1:
            return 'Provisioned';
        case 2:
            return 'Enabled';
        case 3:
            return 'SoftDeleted';
        case 4:
            return 'HardDeleted';
    }
};

const onlineMeetingProvider = (type?: OnlineMeetingProviderType): OnlineMeetingProvider | null => {
    if (type == undefined) {
        return null;
    }

    switch (type) {
        case 0:
            return 'Unknown';
        case 1:
            return 'SkypeForBusiness';
        case 2:
            return 'SkypeForConsumer';
        case 3:
            return 'TeamsForBusiness';
        case 4:
            return 'TeamsForLife';
        case 51:
            return 'BlueJeans';
        case 52:
            return 'GoToMeeting';
        case 53:
            return 'Webex';
        case 54:
            return 'Zoom';
        case 55:
            return 'GoogleMeet';
        case 56:
            return 'JioMeet';
        case 57:
            return 'RingCentral';
        case 58:
            return 'AmazonChimePublic';
        case 59:
            return 'AmazonChimePrivate';
        case 60:
            return 'AlibabaDingTalk';
        case 61:
            return 'FacebookWorkplace';
        case 62:
            return 'AppleFacetime';
        case 63:
            return 'ClaroVideoconferencia';
    }
};

const webSessionType = (type: WebSessionType | undefined): Schema_WebSessionType | null => {
    switch (type) {
        case 0:
            return 'Business';
        case 1:
            return 'ExoConsumer';
        case 2:
            return 'GMail';
        default:
            return null;
    }
};

const weatherEnabledStatus = (
    type: WeatherEnabledStatus | undefined
): Schema_WeatherEnabledStatus | null => {
    switch (type) {
        case 1:
            return 'Disabled';
        case 2:
            return 'Enabled';
        case 0:
            return 'FirstRun';
        default:
            return null;
    }
};

const temperatureUnit = (
    type: TemperatureUnit | WeatherTemperatureUnit | undefined
): Schema_TemperatureUnit | null => {
    switch (type) {
        case 1:
            return 'Celsius';
        case 0:
            return 'Default';
        case 2:
            return 'Fahrenheit';
        default:
            return null;
    }
};

const localEventsEnabledStatus = (
    type: LocalEventsEnabledStatus | undefined
): Schema_LocalEventsEnabledStatus | null => {
    switch (type) {
        case 1:
            return 'Disabled';
        case 2:
            return 'Enabled';
        case 0:
            return 'FirstRun';
        default:
            return null;
    }
};

const newNotification = (type: NewNotification | undefined): Schema_NewNotification | null => {
    switch (type) {
        case 2:
            return 'EmailToast';
        case 8:
            return 'FaxToast';
        case 0:
            return 'None';
        case 1:
            return 'Sound';
        case 4:
            return 'VoiceMailToast';
        default:
            return null;
    }
};

const keyboardShortcutsMode = (
    type: WebKeyboardShortcutsMode | undefined
): KeyboardShortcutsMode | null => {
    switch (type) {
        case 3:
            return 'Gmail';
        case 0:
            return 'Hotmail';
        case 1:
            return 'Off';
        case 4:
            return 'Owa';
        case 2:
            return 'Yahoo';
        default:
            return null;
    }
};

const reactListViewType = (
    type: ReactListViewType | undefined
): Schema_ReactListViewType | null => {
    switch (type) {
        case 2:
            return 'CalendarItems';
        case 0:
            return 'Conversation';
        case 1:
            return 'Message';
        default:
            return null;
    }
};

const clientTypeOptInState = (
    type: ClientTypeOptInState | undefined
): Schema_ClientTypeOptInState | null => {
    switch (type) {
        case 1:
            return 'Jsmvvm';
        case 0:
            return 'None';
        case 2:
            return 'React';
        default:
            return null;
    }
};

const mailSwipeAction = (type: MailSwipeAction | undefined): Schema_MailSwipeAction | null => {
    switch (type) {
        case 2:
            return 'Archive';
        case 1:
            return 'Delete';
        case 0:
            return 'None';
        case 5:
            return 'ToggleFlag';
        case 3:
            return 'TogglePin';
        case 4:
            return 'ToggleRead';
        default:
            return null;
    }
};

const mailTriageAction = (type: MailTriageAction): Schema_MailTriageAction => {
    switch (type) {
        case 2:
            return 'Archive';
        case 1:
            return 'Delete';
        case 0:
            return 'None';
        case 5:
            return 'FlagUnflag';
        case 6:
            return 'Move';
        case 3:
            return 'PinUnpin';
        case 4:
            return 'ReadUnread';
    }
};

const unifiedGroupsFilterType = (
    type: UnifiedGroupsFilterType | undefined
): Schema_UnifiedGroupsFilterType | null => {
    switch (type) {
        case 0:
            return 'All';
        case 4:
            return 'DistributionGroups';
        case 2:
            return 'ExcludeFavorites';
        case 5:
            return 'FamilyGroup';
        case 1:
            return 'Favorites';
        case 3:
            return 'PublicOnly';
        default:
            return null;
    }
};

const groupsSetType = (type: GroupsSetType | undefined): Schema_GroupsSetType | null => {
    switch (type) {
        case 0:
            return 'MemberOfUnifiedGroups';
        case 1:
            return 'OwnerOfDeletedUnifiedGroups';
        default:
            return null;
    }
};

const retentionAction = (type: RetentionActionType): RetentionAction => {
    switch (type) {
        case 3:
            return 'DeleteAndAllowRecovery';
        case 5:
            return 'MarkAsPastRetentionLimit';
        case 6:
            return 'MoveToArchive';
        case 1:
            return 'MoveToDeletedItems';
        case 2:
            return 'MoveToFolder';
        case 0:
            return 'None';
        case 4:
            return 'PermanentlyDelete';
    }
};

const elcFolderType = (type: ElcFolderType): Schema_ElcFolderType => {
    switch (type) {
        case 1:
            return 'Calendar';
        case 2:
            return 'Contacts';
        case 3:
            return 'DeletedItems';
        case 4:
            return 'Drafts';
        case 5:
            return 'Inbox';
        case 6:
            return 'JunkEmail';
        case 7:
            return 'Journal';
        case 8:
            return 'Notes';
        case 9:
            return 'Outbox';
        case 10:
            return 'SentItems';
        case 11:
            return 'Tasks';
        case 12:
            return 'All';
        case 13:
            return 'ManagedCustomFolder';
        case 14:
            return 'RssSubscriptions';
        case 15:
            return 'SyncIssues';
        case 16:
            return 'ConversationHistory';
        case 17:
            return 'Personal';
        case 18:
            return 'RecoverableItems';
        case 19:
            return 'NonIpmRoot';
    }
};

const convertMailTriageActionsToGraphQLSchema = (
    actions: MailTriageAction[] | undefined
): Schema_MailTriageAction[] | null => {
    return actions ? actions.map(mailTriageAction) : null;
};

const convertUnifiedGroupToGraphQLSchema = (group: UnifiedGroup): Schema_UnifiedGroup => ({
    ...group,
    AccessType: unifiedGroupAccessType(group.AccessType),
    ExpiryStatus: unifiedGroupExpiryStatus(group.ExpiryStatus),
});

const convertUnifiedGroupsSetToGraphQLSchema = (
    groupSet: UnifiedGroupsSet
): Schema_UnifiedGroupsSet => {
    return {
        ...groupSet,
        FilterType: unifiedGroupsFilterType(groupSet?.FilterType),
        GroupsSetType: groupsSetType(groupSet?.GroupsSetType),
        Groups: groupSet?.Groups ? groupSet?.Groups.map(convertUnifiedGroupToGraphQLSchema) : null,
    };
};

const convertUnifiedGroupsSetsToGraphQLSchema = (
    groupSets: UnifiedGroupsSet[] | undefined
): Schema_UnifiedGroupsSet[] | null => {
    return groupSets ? groupSets.map(convertUnifiedGroupsSetToGraphQLSchema) : null;
};

const convertDisponsableEmailAddressToGraphQLSchema = (
    address: DisposableEmailAddress
): Schema_DisposableEmailAddress => ({
    ...address,
    State: disposableEmailAddressState(address.State),
});

const convertSessionSettingsToGraphQLSchema = (
    sessionSettings?: SessionSettings
): Schema_SessionSettings => {
    return {
        ...sessionSettings,
        WebSessionType: webSessionType(sessionSettings?.WebSessionType),
        DisposableEmailAddresses: sessionSettings?.DisposableEmailAddresses
            ? sessionSettings?.DisposableEmailAddresses.map(
                  convertDisponsableEmailAddressToGraphQLSchema
              )
            : null,
    };
};

const firstWeekOfYear = (week?: FirstWeekOfYear): Schema_FirstWeekOfYear | undefined => {
    switch (week) {
        case FirstWeekOfYear.FirstDay:
            return 'FirstDay';
        case FirstWeekOfYear.FirstFourDayWeek:
            return 'FirstFourDayWeek';
        case FirstWeekOfYear.FirstFullWeek:
            return 'FirstFullWeek';
        default:
            return undefined;
    }
};

// The service is currently violating it's own schema and returning a string rather than an array of string.
const convertAllowedOnlineMeetingProvidersToGraphQLSchema = (
    providers?: string | string[]
): string[] | null => {
    if (providers === undefined) {
        return null;
    } else if (typeof providers === 'string') {
        return [providers];
    } else {
        return providers;
    }
};

const convertUserOptionsToGraphQLSchema = (userOptions?: UserOptions): Schema_UserOptions => {
    return {
        ...userOptions,
        WeatherEnabled: weatherEnabledStatus(userOptions?.WeatherEnabled),
        WeatherUnit: temperatureUnit(userOptions?.WeatherUnit),
        LocalEventsEnabled: localEventsEnabledStatus(userOptions?.LocalEventsEnabled),
        NewItemNotify: newNotification(userOptions?.NewItemNotify),
        KeyboardShortcutsMode: keyboardShortcutsMode(userOptions?.KeyboardShortcutsMode),
        GlobalListViewTypeReact: reactListViewType(userOptions?.GlobalListViewTypeReact),
        ClientTypeOptInState: clientTypeOptInState(userOptions?.ClientTypeOptInState),
        TasksClientTypeOptInState: clientTypeOptInState(userOptions?.TasksClientTypeOptInState),
        FirstWeekOfYear: firstWeekOfYear(userOptions?.FirstWeekOfYear),
        DefaultOnlineMeetingProvider: onlineMeetingProvider(
            userOptions?.DefaultOnlineMeetingProvider
        ),
        AllowedOnlineMeetingProviders: convertAllowedOnlineMeetingProvidersToGraphQLSchema(
            userOptions?.AllowedOnlineMeetingProviders
        ),
    };
};

const convertViewStateConfigurationToGraphQLSchema = (
    viewStateConfiguration?: ViewStateConfiguration
): Schema_ViewStateConfiguration => {
    return {
        ...viewStateConfiguration,
        TemperatureUnit: temperatureUnit(viewStateConfiguration?.TemperatureUnit),
        MailLeftSwipeAction: mailSwipeAction(viewStateConfiguration?.MailLeftSwipeAction),
        MailRightSwipeAction: mailSwipeAction(viewStateConfiguration?.MailRightSwipeAction),
        MailTriageOnHoverActions: convertMailTriageActionsToGraphQLSchema(
            viewStateConfiguration?.MailTriageOnHoverActions
        ),
    };
};

const convertGroupSetsToGraphQLSchema = (
    groupSets?: UnifiedGroupsSets
): Schema_UnifiedGroupsSets => {
    return {
        UnifiedGroupsSets: convertUnifiedGroupsSetsToGraphQLSchema(groupSets?.UnifiedGroupsSets),
    };
};

const convertRetentionPolicyTagToGraphQLSchema = (
    policyTag: RetentionPolicyTag
): Schema_RetentionPolicyTag => {
    return {
        ...policyTag,
        Type: elcFolderType(policyTag.Type),
        RetentionAction: retentionAction(policyTag.RetentionAction),
    };
};

const convertRetentionPolicyTagsToGraphQLSchema = (
    policyTags?: RetentionPolicyTag[] | undefined
): Schema_RetentionPolicyTag[] | null => {
    return policyTags ? policyTags.map(convertRetentionPolicyTagToGraphQLSchema) : null;
};

export function convertUserConfigToGraphQLSchema(
    config: OwaUserConfiguration,
    mailboxInfo: MailboxInfo
): UserConfiguration {
    return {
        __typename: 'UserConfiguration',
        id: getAccountKeyForMailboxInfo(mailboxInfo),
        ...config,
        UserOptions: convertUserOptionsToGraphQLSchema(config.UserOptions),
        SessionSettings: convertSessionSettingsToGraphQLSchema(config.SessionSettings),
        ViewStateConfiguration: convertViewStateConfigurationToGraphQLSchema(
            config.ViewStateConfiguration
        ),
        GroupsSets: convertGroupSetsToGraphQLSchema(config.GroupsSets),
        RetentionPolicyTags: convertRetentionPolicyTagsToGraphQLSchema(config.RetentionPolicyTags),
    } as UserConfiguration;
}
