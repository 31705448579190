import { type MailRibbonControlId, type MailRibbonTabId } from 'owa-ribbon-ids/lib/mailRibbonId';
import { onConversationsMenuClicked } from '../../utils/deferredOnConversationsMenuClicked';
import { onMessagePreviewMenuClicked } from '../../utils/deferredOnMessagePreviewMenuClicked';
import { onReadingPaneOptionsMenuClicked } from '../../utils/deferredOnReadingPaneOptionsMenuClicked';
import { onRibbonModeMenuClicked } from '../../utils/deferredOnRibbonModeMenuClicked';

import type { ReadOnlyRibbonControlDefProps } from 'owa-mail-ribbon-utils';
import type { RibbonControlProps } from '@1js/acui-ribbon-like/lib/UISurfaces/Ribbon/Controls/RibbonControlProps';
import { owaComputedFn } from 'owa-computed-fn';
import { constructKeytip } from 'owa-command-ribbon';
import { createMailAppFlyoutAnchor } from 'owa-mail-ribbon-utils/lib/createMailRibbonButton';
import { getControlInfo } from 'owa-mail-ribbon-utils/lib/getControlInfo';
import { getMenuDefinition } from '../../../store/menuDefinitionStore';
import { resetMenuDefinitionMutator } from '../../../mutatorActions/menuDefinitionMutatorActions';

export const getConversationsControl = owaComputedFn(function getConversationsControl(
    props: ReadOnlyRibbonControlDefProps
): RibbonControlProps {
    const controlInfo = getControlInfo(644, props);
    const storeName = 'ConversationsMenuDefinitionStore';
    return createMailAppFlyoutAnchor(
        644,
        controlInfo.text,
        controlInfo.tooltip,
        props,
        getMenuDefinition(storeName),
        constructKeytip([3], 644, true /* hasMenu */),
        controlInfo.iconName,
        undefined /* iconColor */,
        undefined /* styles */,
        undefined /* itemId */,
        {
            onExecute: () => {
                onConversationsMenuClicked(props);
            },
            onAfterMenuDismiss: () => {
                resetMenuDefinitionMutator(storeName);
            },
        }
    );
});

export const getMessagePreviewControl = owaComputedFn(function getMessagePreviewControl(
    props: ReadOnlyRibbonControlDefProps
): RibbonControlProps {
    const controlInfo = getControlInfo(645, props);
    const storeName = 'MessagePreviewMenuDefinitionStore';
    return createMailAppFlyoutAnchor(
        645,
        controlInfo.text,
        controlInfo.tooltip,
        props,
        getMenuDefinition(storeName),
        constructKeytip([3], 645, true /* hasMenu */),
        controlInfo.iconName,
        undefined /* iconColor */,
        undefined /* styles */,
        undefined /* itemId */,
        {
            onExecute: () => {
                onMessagePreviewMenuClicked();
            },
            onAfterMenuDismiss: () => {
                resetMenuDefinitionMutator(storeName);
            },
        }
    );
});

export const getReadingPaneControl = owaComputedFn(function getReadingPaneControl(
    props: ReadOnlyRibbonControlDefProps
): RibbonControlProps {
    const controlInfo = getControlInfo(648, props);
    const storeName = 'ReadingPaneOptionsMenuDefinitionStore';
    return createMailAppFlyoutAnchor(
        648,
        controlInfo.text,
        controlInfo.tooltip,
        props,
        getMenuDefinition(storeName),
        constructKeytip([3], 648, true /* hasMenu */),
        controlInfo.iconName,
        undefined /* iconColor */,
        undefined /* styles */,
        undefined /* itemId */,
        {
            onExecute: () => {
                onReadingPaneOptionsMenuClicked();
            },
            onAfterMenuDismiss: () => {
                resetMenuDefinitionMutator(storeName);
            },
        }
    );
});

export const getRibbonModeControl = owaComputedFn(function getRibbonModeControl(
    props: ReadOnlyRibbonControlDefProps
): RibbonControlProps {
    const controlInfo = getControlInfo(691, props);
    const storeName = 'RibbonModeOptionsMenuDefinitionStore';
    return createMailAppFlyoutAnchor(
        691,
        controlInfo.text,
        controlInfo.tooltip,
        props,
        getMenuDefinition(storeName),
        constructKeytip([3], 691, true /* hasMenu */),
        controlInfo.iconName,
        undefined /* iconColor */,
        undefined /* styles */,
        undefined /* itemId */,
        {
            onExecute: () => {
                onRibbonModeMenuClicked();
            },
            onAfterMenuDismiss: () => {
                resetMenuDefinitionMutator(storeName);
            },
        }
    );
});
