import type { NovaEvent } from '@nova/types';
import type { AppHostServiceEventOriginator } from './originator';

export const RefreshPersonInfoEventType = 'refreshPersonInfo' as const;

export interface RefreshPersonInfoEvent extends NovaEvent<RefreshPersonInfoEventData> {
    originator: typeof AppHostServiceEventOriginator;
    type: typeof RefreshPersonInfoEventType;
    data: () => RefreshPersonInfoEventData;
}

export interface RefreshPersonInfoEventData {
    accountId?: string;
    /** PGS ID of the person. */
    personId?: string;
}
