import { createStore } from 'owa-satcheljs';
import type { MailboxInfo } from 'owa-client-types';
import { getIndexerValueForMailboxInfo } from 'owa-client-types';
import type {
    ElaborateFilterScenarios,
    ElaborateFilterSettings,
    AddinCopilotSettings,
    ExtensibilitySettingsResponse,
    LicenseType,
    UserType,
} from '../services/types/serviceSettingTypes';
import registerDiagDataCollector from '../utils/registerDiagDataCollector';
import { getCopilotSettingsResponseFromStartupData } from '../services/helpers/getCopilotSettingsResponseFromStartupData';
import { createFeatureInfoMap } from '../services/helpers/createFeatureMapInfoFromResponse';
import { isBootFeatureEnabled } from 'owa-metatags/lib/isBootFeatureEnabled';
import type { CopilotSettingsResponse } from '../services/types/serviceTypes';

const mailboxIndexerToCopilotSettings = new Map<string, CopilotSettingsStore>();

export type ImplementedCopilotFeatures =
    | 'AttendanceSuggestion'
    | 'Coach'
    | 'Elaborate'
    | 'Summarize'
    | 'SuggestedDrafts'
    | 'SuggestCategory'
    | 'ExplainPlacesReport'
    | 'Rewrite'
    | 'ReplyByMeeting'
    | 'MeetingPrepSummary'
    | 'Chat'
    | 'CoachApply'
    | 'Inbox'
    | 'TimeCopilotChat'
    | 'VisualTheming'
    | 'MChat'
    | 'AttachmentSummarization'
    | 'AutoResourceBooking'
    | 'BizChatHandoff';

export enum FetchState {
    NOT_STARTED,
    STARTUPCACHE,
    IN_PROGRESS,
    SUCCEEDED,
    FAILED,
}

export interface FeatureInfo {
    isEnabled: true;
    langauges: string[];
    capabilities?: CopilotFeatureCapabilities;
    optInEnabled?: boolean;
}

export type CopilotFeatureCapabilities =
    | ElaborateFeatureCapabilities
    | RewriteFeatureCapabilities
    | TimeCopilotChatFeatureCapabilities
    | InboxPrioritizationFeatureCapabilities;

export interface ElaborateFeatureCapabilities {
    tonePersonalization: boolean;
    calendarElaborate: boolean;
}

export interface RewriteFeatureCapabilities {
    highlightAndRewrite: boolean;
    calendarRewrite: boolean;
    autoRewriteStaticPrompt: boolean;
}

export interface TimeCopilotChatFeatureCapabilities {
    triage: boolean;
    schedulingCompactCard: boolean;
    workHoursLocation: boolean;
    speedyMeetings: boolean;
    darkMode: boolean;
    categorizationUpdates: boolean;
    calTimeSuggestionsV2: boolean;
    monCopilotChatContextIq: boolean;
}

export interface InboxPrioritizationFeatureCapabilities {
    backgroundProcessing: boolean;
}

export interface CopilotCapableAddinInfo {
    titleId: string;
    manifestId?: string;
    isOldModelAddin?: boolean;
    acquisitionDate?: string;
    isAdminInstalled: boolean;
    scenarioStrings: Map<ImplementedCopilotFeatures, AddinScenarioStrings>;
    menuIcon?: string;
    fallbackAddinName: string;
    copilotSettings?: AddinCopilotSettings;
}

export interface AddinScenarioStrings {
    menuLabelText?: string;
    menuSelectedText?: string;
    launchButtonText?: string;
}

export interface AddinSettings {
    tenantDefault?: string;
    lastUsedAddin?: string;
}

export type ScenarioToAddinSettingsMap = {
    [key in ImplementedCopilotFeatures]?: AddinSettings;
};

export type FeatureMap = Map<ImplementedCopilotFeatures, FeatureInfo>;

export type AddinsByScenario = Map<ImplementedCopilotFeatures, CopilotCapableAddinInfo[]>;
export type AddinInfoById = Map<string, CopilotCapableAddinInfo>;
interface Extensibility {
    addinsInfo: AddinsByScenario;
    addinInfoById: AddinInfoById;
    scenarioToAddinSettingsMap: ScenarioToAddinSettingsMap;
    settingsFromService?: ExtensibilitySettingsResponse;
}

export interface CopilotSettingsStore {
    isEnabled: boolean;
    featureInfo: FeatureMap;
    extensibility: Extensibility;
    licenses: LicenseType[];
    fetchState: FetchState;
    isBetaUser: boolean;
    userTypes: UserType[];
    copilotAddinsPromise: Promise<void>;
    copilotAddinsPromiseResolve?: () => void;
}

export interface ElaborateSettingsCache {
    elaborateFilterOptions: Map<ElaborateFilterScenarios, ElaborateFilterSettings>;
    tonePersonalizationSeen: boolean;
}

export default function copilotSettingsStore(mailboxInfo: MailboxInfo): CopilotSettingsStore {
    const name = getIndexerValueForMailboxInfo(mailboxInfo) + '_copilotSettingsStore';
    let cachedStore = mailboxIndexerToCopilotSettings.get(name);
    if (!cachedStore) {
        let copilotAddinsPromiseResolve;
        let store: CopilotSettingsStore;
        let cachedStoreResponse: CopilotSettingsResponse | undefined = undefined;

        if (isBootFeatureEnabled('fwk-getcopilot-fromstartup')) {
            cachedStoreResponse = getCopilotSettingsResponseFromStartupData(
                mailboxInfo,
                isBootFeatureEnabled('fwk-getcopilotdeviceid-fromstartup')
            );
        }

        if (isBootFeatureEnabled('fwk-getcopilot-fromstartup') && cachedStoreResponse) {
            store = createStore<CopilotSettingsStore>(name, {
                isEnabled: cachedStoreResponse.copilotCapable.capable,
                licenses: cachedStoreResponse.copilotLicenses,
                featureInfo: createFeatureInfoMap(cachedStoreResponse.supportedScenarios),
                extensibility: {
                    addinsInfo: new Map(),
                    addinInfoById: new Map(),
                    scenarioToAddinSettingsMap: {},
                    settingsFromService: cachedStoreResponse.extensibility,
                },
                fetchState: FetchState.STARTUPCACHE,
                isBetaUser: cachedStoreResponse.isBetaUser,
                userTypes: cachedStoreResponse.userTypes,
                copilotAddinsPromise: new Promise(resolve => {
                    copilotAddinsPromiseResolve = resolve;
                }),
            })();
        } else {
            store = createStore<CopilotSettingsStore>(name, {
                isEnabled: false,
                licenses: [],
                featureInfo: new Map(),
                extensibility: {
                    addinsInfo: new Map(),
                    addinInfoById: new Map(),
                    scenarioToAddinSettingsMap: {},
                },
                fetchState: FetchState.NOT_STARTED,
                isBetaUser: false,
                userTypes: [],
                copilotAddinsPromise: new Promise(resolve => {
                    copilotAddinsPromiseResolve = resolve;
                }),
            })();
        }

        store.copilotAddinsPromiseResolve = copilotAddinsPromiseResolve;
        mailboxIndexerToCopilotSettings.set(name, store);
        cachedStore = store;
    }

    return cachedStore;
}

export function getAllStoreDataForDiagnostics(): string[] {
    const returnArray: string[] = [];
    for (const entry of mailboxIndexerToCopilotSettings.entries()) {
        const [key, storeObj] = entry;
        returnArray.push(
            JSON.stringify({
                storeIndexer: key,
                store: storeObj,
            })
        );
    }
    return returnArray;
}

registerDiagDataCollector();
