import type { MailboxInfo } from 'owa-client-types';
import { mutatorAction } from 'satcheljs';
import { getIndexerValueForMailboxInfo } from 'owa-client-types';
import { getSelectedCalendarsStore } from '../store/store';
import { dedupeArrayValues } from 'owa-selected-calendars-utils';
import { getSelectedCalendarsForUser } from '../selectors/getSelectedCalendarsForUser';
import {
    ConvertIdSource,
    convertIdsToTargetFormat,
    tryConvertIdsToTargetFormat,
} from 'owa-immutable-id';
import { getTargetFormat } from 'owa-immutable-id-store';
import { updateSelectedCalendars, refreshImmutableSelectedCalendars } from './publicActions';
import { isFeatureEnabled } from 'owa-feature-flags';

/* eslint-disable-next-line owa-custom-rules/require-add-identifier-to-mutator-action-variables -- (https://aka.ms/OWALintWiki)
 * Mutator action variables should end with 'Mutator' so that we can more easily identify potential misuses of it.
 *	> Please add 'Mutator' substring add the end of the mutator action variable name. */
export const updateSelectedCalendarsInStore = mutatorAction(
    'UPDATE_SELECTED_CALENDARS_IN_STORE',
    (calendarIds: string[], mailboxInfo: MailboxInfo) => {
        const key = getIndexerValueForMailboxInfo(mailboxInfo);
        getSelectedCalendarsStore().selectedCalendars.set(key, dedupeArrayValues(calendarIds));
    }
);

/* eslint-disable-next-line owa-custom-rules/require-add-identifier-to-mutator-action-variables -- (https://aka.ms/OWALintWiki)
 * Mutator action variables should end with 'Mutator' so that we can more easily identify potential misuses of it.
 *	> Please add 'Mutator' substring add the end of the mutator action variable name. */
export const addSelectedCalendarInStore = mutatorAction(
    'ADD_SELECTED_CALENDAR_IN_STORE',
    (calendarId: string, mailboxInfo: MailboxInfo) => {
        const key = getIndexerValueForMailboxInfo(mailboxInfo);
        getSelectedCalendarsStore().selectedCalendars.set(
            key,
            dedupeArrayValues([...getSelectedCalendarsForUser(mailboxInfo), calendarId])
        );
    }
);

export const formatInitialSelectedCalendars = async (ids: string[], mailboxInfo: MailboxInfo) => {
    const formatFn = () =>
        /* eslint-disable-next-line owa-custom-rules/require-undefined-parameter -- (https://aka.ms/OWALintWiki)
         * Flight checks that supply MailboxInfo should be defined as AccountFeatureName value and should be checked using isAccountFeatureEnabled to ensure consistent checking.
         *	> The parameter mailboxInfo must be undefined. Feature flight: 'cal-rip-selectedCalendarIdTranslate-remove-invalid' */
        isFeatureEnabled('cal-rip-selectedCalendarIdTranslate-remove-invalid', mailboxInfo)
            ? convertIdsToTargetFormat(
                  ids,
                  getTargetFormat(mailboxInfo),
                  mailboxInfo.userIdentity,
                  ConvertIdSource.SelectedCalendars,
                  mailboxInfo
              )
            : tryConvertIdsToTargetFormat(
                  ids,
                  getTargetFormat(mailboxInfo),
                  ConvertIdSource.SelectedCalendars,
                  mailboxInfo
              );
    const formattedResult = await formatFn();

    updateSelectedCalendars(formattedResult, mailboxInfo);
    refreshImmutableSelectedCalendars(mailboxInfo);
};
