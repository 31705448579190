import type { MenuDefinition } from '@1js/acui-menu';
import { createStore } from 'owa-satcheljs';

/**
 * Add to this type when you want to have a new Ribbon button defer its menu loading via stores.
 * Any string added to this array will become an initialized store of schema MenuDefinitionStore.
 */
export type MenuDefinitionStoreNames =
    | 'SnoozeMenuDefinitionStore'
    | 'FlagUnflagMenuDefinitionStore'
    | 'QuickStepsMenuDefinitionStore'
    | 'BlockMenuDefinitionStore'
    | 'RulesMenuDefinitionStore'
    | 'DeleteMenuDefinitionStore'
    | 'ReportMenuDefinitionStore'
    | 'ForwardMenuDefinitionStore'
    | 'AssignPolicyMenuDefinitionStore'
    | 'ChangeNoteColorMenuDefinitionStore'
    | 'RespondMenuDefinitionStore'
    | 'DensityMenuDefinitionStore'
    | 'LayoutMenuDefinitionStore'
    | 'MessagesMenuDefinitionStore'
    | 'NewGroupMenuDefinitionStore'
    | 'ConversationsMenuDefinitionStore'
    | 'MessagePreviewMenuDefinitionStore'
    | 'ReadingPaneOptionsMenuDefinitionStore'
    | 'FolderMenuOptionsMenuDefinitionStore'
    | 'ViewGroupsMenuDefinitionStore'
    | 'MoveToMenuDefinitionStore'
    | 'CategorizeMenuDefinitionStore'
    | 'RibbonModeOptionsMenuDefinitionStore'
    | 'CopilotMeetingMenuDefinitionStore'
    | 'TeamsChatMenuDefinitionStore'
    | 'CopyToMenuDefinitionStore'
    | 'MyDayOptionsMenuDefinitionStore';

/**
 * MenuDefinitionMap maps the static string names of stores to their corresponding MenuDefinition.
 * It maps to a () => MenuDefinition such to avoid stale copies of MenuDefinition.
 */
interface MenuDefinitionMap {
    MenuDefinitionMap: Map<MenuDefinitionStoreNames, () => MenuDefinition>;
}

/**
 * MenuDefinitionStore is the overall store interface that holds a map of maps.
 * The outer map is a map of either <"main_window" | projectionId> to MenuDefinitionMap. This allows for popout
 * projection ribbon scenarios, in order to let each popout control its own menu definition state without colliding.
 * The inner map is a map of storeNames (e.g. "SnoozeMenuDefinitionStore") to the actual MenuDefinition.
 */
interface MenuDefinitionStore {
    menuDefinitionMapManager: Map<string, MenuDefinitionMap>;
}

/**
 * Should there not be a projectionTabId, we assume this is the main window and use this
 * as the string key for the menuDefinitionMapManager.
 */
export const MAIN_WINDOW_ID: string = 'main_window';

/**
 * Initialize the main store with a single entry in the map for the main window.
 * Any popouts will be added to this map as they are created.
 */
export const getStore = createStore<MenuDefinitionStore>('RibbonMenuDefinitionStore', {
    menuDefinitionMapManager: new Map<string, MenuDefinitionMap>([
        [
            // Initializing base case for the main window
            MAIN_WINDOW_ID,
            {
                MenuDefinitionMap: new Map<MenuDefinitionStoreNames, () => MenuDefinition>(),
            },
        ],
    ]),
});

/**
 * Getter function to retrieve a menu definition given a storeName and a ProjectionTabId.
 * Should there not be a store provisioned already for that storeName, we return an empty menu definition.
 * Stores are provisioned during the click event of the Ribbon button, not in this function.
 * @param storeName MenuDefinitionStoreNames e.g. "SnoozeMenuDefinitionStore"
 * @param projectionTabId For read-only projection scenarios.
 * @returns MenuDefinition
 */
export const getMenuDefinition = (
    storeName: MenuDefinitionStoreNames,
    projectionTabId?: string
): MenuDefinition => {
    const id = projectionTabId || MAIN_WINDOW_ID;

    const rootMap = getStore().menuDefinitionMapManager.get(id);
    const storeMap = rootMap?.MenuDefinitionMap.get(storeName);

    if (!rootMap || !storeMap) {
        // Either no store has been created for this contextual instance (projectionTabId)
        // or no store has yet to be created for this store name (which is usually done on click).
        // In either case, we return a default empty menu definition.
        // In main window, we simply return an empty sections.
        // In popout scenarios, we want to return a placeholder content as a workaround
        // to avoid flyout dropdowns in overflow rendering off screen due to sizing miscalculations.
        return id === MAIN_WINDOW_ID ? emptyMenuDefinition : placeholderMenuDefinition;
    }

    return storeMap();
};

export const emptyMenuDefinition: MenuDefinition = {
    sections: [],
};

export const placeholderMenuDefinition: MenuDefinition = {
    sections: [
        {
            controls: [
                {
                    type: 'AppButtonProps',
                    id: 'controlPlaceHolder',
                },
            ],
        },
    ],
};
