import {
    onCreateAndMoveCustomFolderForSpamAddin,
    onMoveToFolderClicked,
} from './onMoveToCommandActions';
import { orchestrator } from 'satcheljs';
import { moveToFolder } from 'owa-addins-wrapper-utils';
import { getStore, getMailboxFolderTreeData } from 'owa-folders';
import type { MailboxInfo } from 'owa-client-types';
import {
    onChangeMoveToMailboxType,
    onChangeMoveToSharedFolderRootId,
} from 'owa-mail-moveto-control/lib/actions/internalActions';
import { PRIMARY_FOLDER_ROOT_DISTINGUISHED_ID, INBOX_FOLDER_NAME } from 'owa-folders-constants';
import type { TraceErrorObject } from 'owa-trace';
import { errorThatWillCauseAlert } from 'owa-trace';
import mailStore from 'owa-mail-store/lib/store/Store';
import { isPopout, lazyClosePopout } from 'owa-popout-v2';
import { getRowKeysFromRowIds, getSelectedTableView } from 'owa-mail-list-store';
import getListViewStore from 'owa-mail-list-store/lib/store/Store';
import { lazyMoveMailListRows } from 'owa-mail-triage-action';
import { resetFocus } from 'owa-mail-focus-manager';
import getAccountDiagnosticDataForMailboxInfo from 'owa-account-source-list-store/lib/utils/getAccountDiagnosticDataForMailboxInfo';
import { isPersistenceIdIndexerEnabled } from 'owa-client-types/lib/isPersistenceIdIndexerEnabled';
import { getAccountKeyForMailboxInfo } from 'owa-client-types';
import type ReactListViewType from 'owa-service/lib/contract/ReactListViewType';

orchestrator(moveToFolder, actionMessage => {
    // Get folderId from folderName
    try {
        const folderId = getFolderId(actionMessage.folderName, actionMessage.mailboxInfo);
        if (folderId) {
            if (isPopout(actionMessage.targetWindow)) {
                // Handle the move to folder action for popout
                processMoveToFolderForPopout(
                    actionMessage.targetWindow,
                    actionMessage.hostItemIndex,
                    folderId
                );
            } else {
                onMoveToFolderClicked(folderId);
            }
        } else {
            // Need to set the moveToStore properties to make sure that the new folder is created in the correct mailbox,
            // this is required to keep parity with other OWA actions like folder tree-view
            onChangeMoveToMailboxType(actionMessage.mailboxInfo.type);
            onChangeMoveToSharedFolderRootId(getInboxFolderIdForMailbox(actionMessage.mailboxInfo));

            // Create the new folder and move the item to it
            onCreateAndMoveCustomFolderForSpamAddin(
                actionMessage.folderName,
                actionMessage.mailboxInfo
            );
        }
    } catch (error) {
        error.additionalInfo = {
            ...error.additionalInfo,
            ...getAccountDiagnosticDataForMailboxInfo(actionMessage.mailboxInfo),
        };

        errorThatWillCauseAlert('IntegratedSpamReportMoveToFolderError', error);
        return;
    }
});

export async function processMoveToFolderForPopout(
    targetWindow: Window | undefined,
    hostItemIndex: string,
    folderId: string
) {
    const itemId = hostItemIndex.replace('extRead_', '');
    const item = mailStore.items.get(itemId);

    if (!item) {
        const error: TraceErrorObject = new Error('Item not found for hostItemIndex');
        error.additionalInfo = { hostItemIndex };
        throw error;
    }

    if (!item.ConversationId) {
        const error: TraceErrorObject = new Error('ConversationId not found for item');
        error.additionalInfo = { itemId: item.ItemId?.Id };
        throw error;
    }

    const currentTableView = getSelectedTableView();
    let selectedTableViewId = currentTableView.id;
    const isMessageView = currentTableView.tableQuery.listViewType === 1;
    let rowKeys = isMessageView
        ? getRowKeysFromRowIds([itemId], currentTableView)
        : getRowKeysFromRowIds([item.ConversationId.Id], currentTableView);

    // If rowKeys are not found in the selectedTableView, then it means that the user has selected a
    // different folder in the main window, or the item was part of a search result, hence we now need to
    // search other table views to find the rowKeys.
    if (rowKeys.length === 0) {
        const allTableViews = getListViewStore().tableViews.values();
        for (const tableView of allTableViews) {
            const rowKeysFromTableView = isMessageView
                ? getRowKeysFromRowIds([itemId], tableView)
                : getRowKeysFromRowIds([item.ConversationId.Id], tableView);
            if (rowKeysFromTableView.length > 0) {
                rowKeys = rowKeysFromTableView;
                selectedTableViewId = tableView.id;
                break;
            }
        }
    }

    // Move the popped-out item to the destination folder
    await lazyMoveMailListRows.importAndExecute(rowKeys, folderId, selectedTableViewId);

    // Close the popout window after moving the item to the specific folder
    lazyClosePopout.importAndExecute(targetWindow);
    // Reset focus to the item in the main window, after the popped-out item is moved to a folder
    resetFocus('moveToFolderForPopout');
}

/**
 * Get the folder-id for a folder with the given name.
 *
 * @param {string} folderName
 * @param {MailboxInfo} [mailboxInfo]
 * @returns {string}
 */
function getFolderId(folderName: string, mailboxInfo: MailboxInfo): string {
    // To ensure that we are honoring the correct user-account
    const mailboxFolderStore = isPersistenceIdIndexerEnabled()
        ? getMailboxFolderTreeData(mailboxInfo)
        : getStore()?.mailboxFolderTreeData?.get(getAccountKeyForMailboxInfo(mailboxInfo));
    if (!mailboxFolderStore) {
        const error: TraceErrorObject = new Error('Folder store not initialized for user');
        throw error;
    }
    const mailboxFolderTable =
        mailboxFolderStore?.folderTreeTable?.get(mailboxInfo.mailboxSmtpAddress) ??
        mailboxFolderStore?.folderTreeTable?.get(PRIMARY_FOLDER_ROOT_DISTINGUISHED_ID);

    // Default folders like Deleted Items and Junk are at the root level of the mailbox
    if (mailboxFolderTable && mailboxFolderTable.rootFolder?.childFolderIds) {
        const childFolderId = findChildFolderId(
            mailboxFolderTable.rootFolder.childFolderIds,
            folderName
        );
        if (childFolderId) {
            return childFolderId;
        }
    }

    // If not a default folder, then check if the folder is a child of the Inbox folder
    const inboxFolderId = getInboxFolderIdForMailbox(mailboxInfo);
    if (inboxFolderId) {
        const inboxFolder = getStore().folderTable.get(inboxFolderId);
        if (inboxFolder && inboxFolder.childFolderIds) {
            const childFolderId = findChildFolderId(inboxFolder.childFolderIds, folderName);
            if (childFolderId) {
                return childFolderId;
            }
        }
    }

    return '';
}

/**
 * Returns the folder-id for the inbox folder of the given mailbox.
 *
 * @param {MailboxInfo} mailboxInfo
 * @returns {string}
 */
function getInboxFolderIdForMailbox(mailboxInfo: MailboxInfo): string {
    const mailboxFolderStore = isPersistenceIdIndexerEnabled()
        ? getMailboxFolderTreeData(mailboxInfo)
        : getStore()?.mailboxFolderTreeData?.get(getAccountKeyForMailboxInfo(mailboxInfo));
    const folderTable = getStore().folderTable;
    const mailboxFolderTable =
        mailboxFolderStore?.folderTreeTable?.get(mailboxInfo.mailboxSmtpAddress) ??
        mailboxFolderStore?.folderTreeTable?.get(PRIMARY_FOLDER_ROOT_DISTINGUISHED_ID);

    if (!mailboxFolderTable || !mailboxFolderTable.rootFolder?.childFolderIds) {
        return '';
    }

    for (const childFolderId of mailboxFolderTable.rootFolder.childFolderIds) {
        const folder = folderTable.get(childFolderId);
        if (folder && folder.distinguishedFolderType === INBOX_FOLDER_NAME) {
            return childFolderId;
        }
    }

    return '';
}

/**
 * Match folderName with the child folders of the given folderIds and return the folder-id if found.
 *
 * @param {string[]} folderIds
 * @param {string} folderName
 * @returns {string}
 */
function findChildFolderId(folderIds: string[], folderName: string): string {
    const folderTable = getStore().folderTable;
    for (const childFolderId of folderIds) {
        const folder = folderTable.get(childFolderId);
        if (
            folder &&
            (folder.distinguishedFolderType === folderName || folder.displayName === folderName)
        ) {
            return childFolderId;
        }
    }
    return '';
}
