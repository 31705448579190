import loadConversationReadingPane from './loadConversationReadingPane';
import { updatePrimaryReadingPaneTabId } from 'owa-mail-reading-pane-store/lib/mutators/primaryReadingPaneTabIdMutators';
import type ConversationReadingPaneViewState from 'owa-mail-reading-pane-store/lib/store/schema/ConversationReadingPaneViewState';
import getConversationReadingPaneViewState from '../utils/getConversationReadingPaneViewState';
import type { ClientItemId } from 'owa-client-ids';
import { lazyMoveComposeToTab } from 'owa-mail-compose-actions';
import findComposeFromTab from 'owa-mail-compose-actions/lib/utils/findComposeFromTab';
import type { ComposeViewState } from 'owa-mail-compose-store';
import { type AsyncSendState } from 'owa-mail-compose-store';
import { getFolderIdForSelectedNode } from 'owa-mail-folder-forest-store';
import mailStore from 'owa-mail-store/lib/store/Store';
import type { InstrumentationContext } from 'owa-search-types/lib/types/InstrumentationContext';
import type ReactListViewType from 'owa-service/lib/contract/ReactListViewType';
import { getActiveContentTab, TabType } from 'owa-tab-store';
/* eslint-disable-next-line @typescript-eslint/no-restricted-imports  -- (https://aka.ms/OWALintWiki)
 * satcheljs/lib/legacy imports are not allowed
 *	> 'satcheljs/lib/legacy' import is restricted from being used. */
import { action } from 'satcheljs/lib/legacy';
import type { LoadConversationItemActionSource } from 'owa-mail-store';
import type MailListItemSelectionSource from 'owa-mail-store/lib/store/schema/MailListItemSelectionSource';
import { type TableView } from 'owa-mail-list-store';
import { isServiceRequestSupportedForMailbox } from 'owa-service/lib/utils/isServiceRequestSupportedForMailbox';
import { isImmutableId } from 'owa-immutable-id-store';
import { getModuleContextMailboxInfo } from 'owa-module-context-mailboxinfo';
import { isFeatureEnabled } from 'owa-feature-flags';
import { translateEwsIdsToEwsImmutableIds, ConvertIdSource } from 'owa-immutable-id';
import getUserConfiguration from 'owa-session-store/lib/actions/getUserConfiguration';
import { MailRowDataPropertyGetter } from 'owa-mail-list-store';
import { isSharedCoprincipalAccountEnabled } from 'owa-anchormailbox/lib/isSharedCoprincipalAccountEnabled';
import { isMailboxSharedOrDelegateUserMailbox } from 'owa-anchormailbox/lib/isMailboxSharedOrDelegateUserMailbox';

export interface LoadConversationReadingPaneForSingleMailItemSelectedState {
    conversationReadingPaneState: ConversationReadingPaneViewState;
    hasConversationInMailStore: boolean;
}

export default action('loadConversationReadingPaneForSingleMailItemSelected')(
    async function loadConversationReadingPaneForSingleMailItemSelected(
        conversationId: ClientItemId,
        actionSource: LoadConversationItemActionSource,
        isUserNavigation: boolean,
        instrumentationContext: InstrumentationContext,
        conversationSubject: string,
        conversationCategories: string[],
        conversationCopilotInboxScore: number,
        mailListItemSelectionSource?: MailListItemSelectionSource,
        isSingleLineView?: boolean,
        rowKey?: string,
        tableView?: TableView,
        state: LoadConversationReadingPaneForSingleMailItemSelectedState = {
            conversationReadingPaneState: getConversationReadingPaneViewState(),
            hasConversationInMailStore: mailStore.conversations.has(conversationId?.Id),
        }
    ): Promise<void> {
        instrumentationContext?.dp?.addCheckpoint?.('LCRPFSMIS');
        // Skip if conversation id is null
        if (!conversationId) {
            return Promise.resolve();
        }
        const activeTab = getActiveContentTab();
        const composeViewState: ComposeViewState | undefined = state.conversationReadingPaneState
            ? findComposeFromTab(activeTab, state.conversationReadingPaneState.conversationId.Id, 0)
            : undefined;
        // Skip if user has not explicitly asked to navigate to a different conversation (!isUserNavigation)
        if (composeViewState && composeViewState.asyncSendState !== 6 && !isUserNavigation) {
            return Promise.resolve();
        }
        // Loading the same conversation in the same folder
        if (
            state.conversationReadingPaneState?.conversationId.Id == conversationId.Id &&
            state.conversationReadingPaneState?.currentSelectedFolderId ==
                getFolderIdForSelectedNode() &&
            state.hasConversationInMailStore
        ) {
            return Promise.resolve();
        }

        if (
            composeViewState &&
            activeTab &&
            activeTab.type != TabType.SecondaryReadingPane &&
            !composeViewState.isInlineCompose
        ) {
            // In the drafts folder, full compose opens in Primary tab.
            lazyMoveComposeToTab.importAndExecute(composeViewState, true, false);
        }

        updatePrimaryReadingPaneTabId(conversationId);

        let itemToScrollTo = null;
        if (
            getUserConfiguration().SessionSettings?.IsSubstrateSearchServiceAvailable &&
            instrumentationContext &&
            rowKey &&
            tableView
        ) {
            // 3S returns the overloads the itemIds property and stores the most relevant item in a conversation
            // as the first element of that list.  This property is mapped to the MailRow itemIds property
            // in this function - convertSearchResultConversationToConversationType
            const itemIds = MailRowDataPropertyGetter.getItemIds(rowKey, tableView);
            itemToScrollTo = itemIds ? itemIds[0] : null;
            if (
                isFeatureEnabled('fwk-immutable-ids') &&
                itemToScrollTo &&
                !isImmutableId(itemToScrollTo)
            ) {
                const mailboxInfo = getModuleContextMailboxInfo();
                if (
                    mailboxInfo?.type != 'GroupMailbox' &&
                    mailboxInfo?.type != 'ArchiveMailbox' &&
                    mailboxInfo?.type != 'SharedMailbox' &&
                    !(
                        isSharedCoprincipalAccountEnabled() &&
                        isMailboxSharedOrDelegateUserMailbox(mailboxInfo)
                    ) &&
                    isServiceRequestSupportedForMailbox(mailboxInfo)
                ) {
                    itemToScrollTo = (
                        await translateEwsIdsToEwsImmutableIds(
                            [itemToScrollTo],
                            ConvertIdSource.Search,
                            undefined,
                            mailboxInfo
                        )
                    )[0];
                }
            }
        }

        const loadConversationReadingPanePromise = loadConversationReadingPane(
            conversationId,
            actionSource,
            instrumentationContext,
            conversationSubject,
            conversationCategories,
            conversationCopilotInboxScore,
            itemToScrollTo ?? undefined,
            mailListItemSelectionSource,
            isSingleLineView
        );

        return loadConversationReadingPanePromise;
    }
);
