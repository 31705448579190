import { LazyAction, LazyModule } from 'owa-bundling';
export {
    default as copilotSettingsStore,
    type ImplementedCopilotFeatures,
    type CopilotSettingsStore,
    type CopilotCapableAddinInfo,
    type ElaborateFeatureCapabilities,
    type RewriteFeatureCapabilities,
    type CopilotFeatureCapabilities,
    type AddinSettings,
    FetchState,
} from './store/store';
export { elaborateSettingsCache } from './store/storeTypes';

export { ElaborateCapabilitiesType, RewriteCapabilitiesType } from './services/types/serviceTypes';
export { LicenseType } from './services/types/serviceSettingTypes';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "CopilotSettings" */ './lazyIndex'),
    { name: 'CopilotSettings' }
);

export const lazyFetchCopilotSettings = new LazyAction(lazyModule, m => m.fetchCopilotSettings);
export const lazySetAddinSettingsToOcs = new LazyAction(lazyModule, m => m.setAddinSettingsToOcs);

export { isSalesCopilotAddin } from './utils/isSalesCopilotAddin';
export {
    OUTLOOK_ADDIN_TITLE_ID,
    OUTLOOK_ADDIN_MANIFEST_ID,
    SALES_COPILOT_METAOS_ADDIN_IDS,
} from './constants/copilotAddinIds';
export { Scenario } from './services/types/serviceSettingTypes';

export { getAddinSettingsForScenario } from './selectors/getAddinSettingsForScenario';
export { getTenantDefaultAddin } from './selectors/getTenantDefaultAddin';
export { isOldModelAddin } from './selectors/isOldModelAddin';
export { getManifestIdForAddin } from './selectors/getManifestIdForAddin';
export { getAddinIconUrl } from './selectors/getAddinIconUrl';
export { isScenarioExtensible } from './utils/isScenarioExtensible';
export { isSalesLicensePresent } from './selectors/isSalesLicensePresent';
export { default as isAIX00User } from './selectors/isAIX00User';
export { default as isCopilotStoreReady } from './utils/isCopilotStoreReady';
export { default as isOptInExperienceEnabledForScenario } from './selectors/isOptInExperienceEnabledForScenario';

export const lazyGetAndSetCopilotCapableAddins = new LazyAction(
    lazyModule,
    m => m.getAndSetCopilotCapableAddins
);

export const lazyGetNativeHostDeviceId = new LazyAction(lazyModule, m => m.getNativeHostDeviceId);
