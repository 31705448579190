import { getStore } from '../store/store';
import { getModuleContextMailboxInfo } from 'owa-module-context-mailboxinfo';
import { isMailboxSharedOrDelegate } from 'owa-account-shared-mailbox-utils';

export default function isTabbedSearchEnabled(isFromQfScopeTabs: boolean): boolean {
    const mailboxInfo = getModuleContextMailboxInfo();

    // Suppress SERP tabs if the store flag is set and the check is not from QF scope tabs
    if (getStore().shouldSuppressTabs && !isFromQfScopeTabs) {
        return false;
    }
    // If shared mailbox as resource, as account, or explicit logon/delegate access, disable tabbed search
    if (isMailboxSharedOrDelegate(mailboxInfo)) {
        return false;
    }

    return true;
}
