import type { ReadOnlyRibbonControlDefProps } from 'owa-mail-ribbon-utils';
import type { RibbonControlProps } from '@1js/acui-ribbon-like';
import { owaComputedFn } from 'owa-computed-fn';
import { getControlInfo } from 'owa-mail-ribbon-utils/lib/getControlInfo';
import { type RibbonIconColor, getRibbonIconModeColor } from 'owa-command-ribbon';
import { type MailRibbonControlId } from 'owa-ribbon-ids/lib/mailRibbonId';
import { getQuickStepGalleryStyles, getQuickStepsGalleryItems } from '../../util/quickStepsUtils';
import { onQuickStepsMenuClicked } from '../utils/deferredOnQuickStepsMenuClicked';
import { onQuickStepGalleryButtonExecute } from 'owa-mail-ribbon-command-actions/lib/quickStepsCommandActions';
import { resetMenuDefinitionMutator } from '../../mutatorActions/menuDefinitionMutatorActions';
import { getMenuDefinition } from '../../store/menuDefinitionStore';

export const getQuickStepsControl = owaComputedFn(function getQuickStepsControl(
    isSLR: boolean,
    props: ReadOnlyRibbonControlDefProps
): RibbonControlProps {
    const galleryItems = getQuickStepsGalleryItems(isSLR, props);
    const controlInfo = getControlInfo(550, props);
    const storeName = 'QuickStepsMenuDefinitionStore';

    return {
        type: 'AppIconPreviewGalleryProps',
        id: (550).toString(),
        galleryButtons: galleryItems,
        onGalleryButtonExecute: onQuickStepGalleryButtonExecute(props),
        onMenuButtonExecute: () => onQuickStepsMenuClicked(props),
        onAfterMenuDismiss: () => resetMenuDefinitionMutator(storeName),
        expandedOptions: getMenuDefinition(storeName),
        stack: true,
        styles: getQuickStepGalleryStyles(isSLR, galleryItems.length),
        shadowRenderMenu: false,
        flyoutAnchorProps: {
            label: controlInfo.text,
            icon: controlInfo.iconName,
            styles: {
                icon: {
                    color: getRibbonIconModeColor(2),
                },
            },
        },
        menuLauncherLabel: controlInfo.ariaText,
    };
});
