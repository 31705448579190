import { FluentButton } from 'owa-fluent-v9-shims';
import SearchRegular from 'owa-fluent-icons-svg/lib/icons/SearchRegular';
import ArrowLeftRegular from 'owa-fluent-icons-svg/lib/icons/ArrowLeftRegular';
import { computed, makeObservable } from 'mobx';
import { observer } from 'owa-mobx-react';
import { Icon } from '@fluentui/react/lib/Icon';
import { elementContains, KeyCodes } from '@fluentui/react/lib/Utilities';
import type { AriaProperties } from 'owa-accessibility';
import { AriaRoles, generateDomPropertiesForAria } from 'owa-accessibility';
import { logUsage } from 'owa-analytics';
/* eslint-disable-next-line @typescript-eslint/no-restricted-imports  -- (https://aka.ms/OWALintWiki)
 * Baseline - Migrating deprecated icons: https://outlookweb.visualstudio.com/Outlook%20Web/_wiki/wikis/Outlook%20Web.wiki/9297/Migrating-deprecated-icons-in-client-web
 *	> 'ControlIcons' import from owa-control-icons is restricted. Use JSX icons from @fluentui/react-icons, otherwise from owa-fluent-icons-svg if they can only be reference by string. */
import { type ControlIcons } from 'owa-control-icons';
import { ErrorBoundary, ErrorComponentType } from 'owa-error-boundary';
/* eslint-disable-next-line @typescript-eslint/no-restricted-imports -- (https://aka.ms/OWALintWiki)
 * Remove reference to getHostValue
 *	> 'getHostValue' import from 'owa-config' is restricted. Please use isFeatureEnabled/isBootFeatureEnabled instead. Host value is checked with ECS/CT as a filter. */
import { NATIVE, getHostValue } from 'owa-config';
import { isFeatureEnabled } from 'owa-feature-flags';
import loc from 'owa-localize';
import SearchPlaceholderStrings from 'owa-locstrings/lib/strings/searchplaceholder.locstring.json';
import {
    CompactSearchBox,
    lazyIsSearchBoxEmpty,
    SearchBoxPillWell,
    SuggestionsCallout,
} from '../lazyFunctions';
import React from 'react';
import { lazyGetHostCapabilitiesProxy } from 'native-outlook-host';
import {
    clearSearchBox,
    exitSearch,
    lazyRemoveSuggestionPillFromSearchBox,
    onSearchTextChanged,
    searchBoxBlurred,
    setIsSuggestionsDropdownVisible,
    setSearchTextForSuggestion,
    startSearch,
    startSearchSession,
    suggestionSelected,
    initializeRefinersScenarioStore,
} from 'owa-search-actions';
import {
    SEARCH_SCOPE_SWITCHER_ID,
    SEARCH_SCOPE_SWITCHER_ID_OPTION,
    SEARCH_SCOPE_SWITCHER_ID_LIST,
    SEARCH_SCOPE_SWITCHER_WRAPPER_ID,
    SEARCHBOX_ANCHOR_ID,
    SEARCHBOX_ID,
    SEARCHBOX_COLUMN_CONTAINER_ID,
    LPC_CARD_ID,
} from 'owa-search-constants';
import hasSuggestionPills from '../selectors/hasSuggestionPills';
import isSearchBoxEmpty from '../selectors/isSearchBoxEmpty';
import { SearchScenarioId, initializeScenarioStore, getScenarioStore } from 'owa-search-store';
import getSearchBoxIdByScenarioId from '../utils/getSearchBoxIdByScenarioId';
import getSearchIconIdByScenarioId from '../utils/getSearchIconIdByScenarioId';
import getSearchBoxMaxWidth from '../utils/getSearchBoxMaxWidth';
import Strings from './SearchBox.locstring.json';
import type { SearchInputHandle } from './SearchInput';
import SearchInput from './SearchInput';
import { Announced } from 'owa-controls-announced';
import { getIsDarkTheme } from 'owa-fabric-theme';

import classNames from 'owa-classnames';
import {
    searchBoxCommonContainer,
    isInCompactMode,
    searchBoxContainerInSearch,
    searchBoxContainer as styles_searchBoxContainer,
    searchBoxContainerNoBorderRadius,
    darkMode,
    draggableRegion,
    nonDraggableRegion,
    lightMode,
    searchBox,
    isDisabled as styles_isDisabled,
    searchIcon,
    searchBoxColumnContainer,
    backButton,
    searchQueryContainer,
    clearBoxButton,
    searchButton,
} from './SearchBox.scss';
import { shouldUseAppHostSearch } from 'owa-app-host-search/lib/utils/shouldUseAppHostSearch';
import { getSelectedApp } from 'owa-appbar-state';
import { LaunchPageAppId } from 'owa-m365-acquisitions/lib/data/M365AppId';

export interface SearchBoxProps {
    scenarioId: SearchScenarioId; // ID representing the search scenario (unique per scenario)
    customCollapsedMaxWidth?: number; // The max width when the control is collapsed
    isUsing3S?: boolean; // Is using 3S for search suggestions (for instrumentation)
    renderRefiners?: () => JSX.Element; // Render prop for search refiners button and menu
    searchPlaceHolderText?: string; // Placeholder string to be displayed in search input
    isDisabled?: boolean; // Boolean indicating if search box is disabled (default is false)
    showCompactSearchBox?: boolean; // Flag indicating if search box should be rendered in compact mode
    isSuggestCalloutDisabled?: boolean; // Boolean indicating if suggestion callout disabled (default is false)
    disableAutoFocusWhenActivated?: boolean; // Boolean indicating whether to disable automatically focusing when search session activates (default is false)
    isHovered?: boolean;
    onHoverHandlers?: {
        onMouseEnter?: () => void;
        onMouseLeave?: () => void;
    };
    ariaLabel?: string; // Aria label for the search box
    shouldSuppressScopedSuggestions?: boolean; // Boolean indicating whether to turn off scoped suggestions
}

// Const to assign instead of using new object as prop
const leftSideIconNameProps = { iconName: ArrowLeftRegular };
const clearBoxIconStyles = { root: { fontSize: '8px' } };
const searchButtonIconProps = { iconName: SearchRegular };

/* eslint-disable-next-line owa-custom-rules/prefer-react-functional-components -- (https://aka.ms/OWALintWiki)
 * This react class component should be re-factored as functional component, if is not possible, write a justification.
 *	> When implementing a simple component, write it as a functional component rather than as a class component */
class SearchBox extends React.Component<SearchBoxProps, {}> {
    private searchBoxContainer: HTMLDivElement | null = null;
    private searchInput: SearchInputHandle | null = null;
    private previousSearchActiveValue: boolean = false;
    private interactiveDraggableRegionsEnabled: boolean = false;

    constructor(props: SearchBoxProps) {
        super(props);

        const { isUsing3S = true } = props;

        // Initialize store for scenario.
        initializeScenarioStore(props.scenarioId, isUsing3S);
        initializeRefinersScenarioStore(props.scenarioId);

        makeObservable(this, {
            isSuggestionsCalloutVisible: computed,
            isClearSearchBoxButtonVisible: computed,
        });
    }

    async componentDidMount(): Promise<void> {
        let hasCapabilities = false;
        try {
            const hostCapabilities = await lazyGetHostCapabilitiesProxy.importAndExecute();
            if (hostCapabilities !== undefined) {
                hasCapabilities = true;
                this.interactiveDraggableRegionsEnabled =
                    hostCapabilities.interactiveDraggableRegionsEnabled;
            }
        } catch (e) {
            logUsage('Search_InteractiveDraggableRegionsError');
        }

        if (hasCapabilities) {
            logUsage('Search_InteractiveDraggableRegionsEnabled', [
                this.interactiveDraggableRegionsEnabled,
            ]);
        }
    }

    componentDidUpdate(prevProps: SearchBoxProps) {
        /**
         * Focus on search input if the component is transitioning from inactive to
         * active mode while in the compact mode as search input is not present in the DOM
         * when use clicks on the compact search icon so synchronously putting focus on
         * the search input right after the click does not work
         */
        const isSearchActive = this.isInActiveSearchSession();
        if (
            this.props.showCompactSearchBox == prevProps.showCompactSearchBox &&
            isSearchActive &&
            !this.previousSearchActiveValue &&
            !this.props.disableAutoFocusWhenActivated
        ) {
            this.focusSearchInput();
        }

        this.previousSearchActiveValue = isSearchActive;
    }

    /**
     * This computed represents if suggestions callout is shown.
     */
    get isSuggestionsCalloutVisible(): boolean {
        const { isSuggestionsDropdownVisible, currentSuggestions } = getScenarioStore(
            this.props.scenarioId
        );

        return (
            !this.props.isSuggestCalloutDisabled &&
            !!currentSuggestions?.Suggestions &&
            isSuggestionsDropdownVisible
        );
    }

    get isClearSearchBoxButtonVisible(): boolean {
        if (!isFeatureEnabled('sea-searchBoxInteractionsClear')) {
            return false;
        }

        // Visible if search box isn't empty.
        return !isSearchBoxEmpty(this.props.scenarioId);
    }

    private onExitClick = () => {
        this.onExit('SearchBoxBackArrow');
    };

    get searchBoxContainerStyle() {
        const isSearchBoxExpanded = this.isInActiveSearchSession();
        const hasHostIcons = getHostValue() === NATIVE;
        const showInCompactMode = getScenarioStore(
            this.props.scenarioId
        ).showSearchBoxInCompactMode;
        const customCollapsedMaxWidth = this.props.customCollapsedMaxWidth
            ? this.props.customCollapsedMaxWidth
            : 0;
        return {
            maxWidth: `${getSearchBoxMaxWidth(isSearchBoxExpanded, customCollapsedMaxWidth)}px`,
            width: hasHostIcons && showInCompactMode && isSearchBoxExpanded ? 'auto' : '100%',
            marginRight: hasHostIcons && showInCompactMode && isSearchBoxExpanded ? '180px' : '0px', // 180px is the width of the host icons
        };
    }

    render() {
        const { isHovered, isDisabled, scenarioId, searchPlaceHolderText } = this.props;
        const showCompactSearchBox = getScenarioStore(scenarioId).showSearchBoxInCompactMode;

        const isSearchBoxExpanded = this.isInActiveSearchSession();

        const searchBoxContainerStyle = this.searchBoxContainerStyle;

        // Search box should be draggable if the host has enabled draggable regions and it's
        // not in an active search session
        const isDraggable = this.interactiveDraggableRegionsEnabled && !isSearchBoxExpanded;

        const searchBoxCommonContainerClassNames = classNames(
            searchBoxCommonContainer,
            showCompactSearchBox && isInCompactMode,
            isSearchBoxExpanded && searchBoxContainerInSearch,
            isDraggable ? draggableRegion : nonDraggableRegion
        );

        const searchBoxContainerClassNames = classNames(
            styles_searchBoxContainer,
            isSearchBoxExpanded && searchBoxContainerNoBorderRadius,
            isSearchBoxExpanded && searchBoxContainerInSearch,
            showCompactSearchBox && isInCompactMode,
            isDraggable ? draggableRegion : nonDraggableRegion
        );

        if (showCompactSearchBox && !isSearchBoxExpanded) {
            return (
                <div
                    role={'search'}
                    id={SEARCHBOX_COLUMN_CONTAINER_ID}
                    className={searchBoxContainerClassNames}
                    /* eslint-disable-next-line react-perf/jsx-no-new-function-as-prop  -- (https://aka.ms/OWALintWiki)
                     * Baseline, please do not copy and paste this justification
                     *	> JSX attribute values should not contain functions created in the same scope */
                    ref={ref => (this.searchBoxContainer = ref)}
                >
                    <CompactSearchBox onClick={this.onCompactSearchButtonClick} />
                </div>
            );
        }

        const themeColorClass = getIsDarkTheme() ? darkMode : lightMode;
        const searchBoxClassNames = classNames(
            searchBox,
            isDisabled && styles_isDisabled,
            themeColorClass
        );

        const searchGlyphClassNames = classNames(
            searchIcon,
            this.props.isDisabled && styles_isDisabled
        );

        const suggestionsComponent = this.isSuggestionsCalloutVisible ? (
            <SuggestionsCallout
                scenarioId={scenarioId}
                focusSearchInput={this.focusSearchInput}
                shouldSuppressScopedSuggestions={this.props.shouldSuppressScopedSuggestions}
            />
        ) : null;

        const clearSearchBoxButtonAriaProperties: AriaProperties = {
            role: AriaRoles.button,
            label: loc(Strings.clearSearchLabel),
        };

        const searchBoxColumnClassNames = classNames(
            searchBoxColumnContainer,
            showCompactSearchBox && isInCompactMode
        );

        // Announce when suggestions are available and visible
        const announcement = this.isSuggestionsCalloutVisible
            ? loc(Strings.suggestionsAnnouncement)
            : '';

        return (
            /**
             * Outer div is necessary to make this component have a single root element.
             */
            <div style={searchBoxContainerStyle} className={searchBoxCommonContainerClassNames}>
                {/**
                 * Flex column container div is necessary so that the filters anchor div gets proper width
                 */}
                {/* id is used by one-outlook-suite-header to track this div for hit testing */}
                <div className={searchBoxColumnClassNames} id={SEARCHBOX_COLUMN_CONTAINER_ID}>
                    <div
                        role={'search'}
                        tabIndex={-1}
                        className={searchBoxContainerClassNames}
                        /* eslint-disable-next-line react-perf/jsx-no-new-function-as-prop  -- (https://aka.ms/OWALintWiki)
                         * Baseline, please do not copy and paste this justification
                         *	> JSX attribute values should not contain functions created in the same scope */
                        ref={ref => (this.searchBoxContainer = ref)}
                        aria-expanded={this.isSuggestionsCalloutVisible}
                        onBlur={this.onSearchBoxBlur}
                        {...this.props.onHoverHandlers}
                    >
                        {/* Search box */}
                        <div
                            id={getSearchBoxIdByScenarioId(scenarioId)}
                            className={searchBoxClassNames}
                        >
                            {
                                /* Suggestions UI */
                                <ErrorBoundary type={ErrorComponentType.None}>
                                    {suggestionsComponent}
                                    <Announced message={announcement} />
                                </ErrorBoundary>
                            }

                            {/* Left side (back button or search icon) */}
                            {isSearchBoxExpanded ? (
                                <FluentButton
                                    appearance="icon"
                                    className={classNames(backButton, 'flipForRtl')}
                                    iconProps={leftSideIconNameProps}
                                    onClick={this.onExitClick}
                                    aria-label={loc(Strings.exitSearchTitle)}
                                    title={loc(Strings.exitSearchTitle)}
                                />
                            ) : (
                                <div
                                    aria-hidden={true}
                                    className={searchGlyphClassNames}
                                    onClick={this.onSearchBoxClick}
                                    id={getSearchIconIdByScenarioId(scenarioId)}
                                >
                                    <Icon iconName={SearchRegular} />
                                </div>
                            )}

                            {/* Search input (pills and input) */}
                            <div className={searchQueryContainer} onClick={this.onSearchBoxClick}>
                                {hasSuggestionPills(this.props.scenarioId) && (
                                    <SearchBoxPillWell
                                        focusSearchInput={this.focusSearchInput}
                                        onRemovePill={this.onRemovePill}
                                        scenarioId={this.props.scenarioId}
                                    />
                                )}
                                <SearchInput
                                    /* eslint-disable-next-line react-perf/jsx-no-new-function-as-prop  -- (https://aka.ms/OWALintWiki)
                                     * Baseline, please do not copy and paste this justification
                                     *	> JSX attribute values should not contain functions created in the same scope */
                                    ref={ref => (this.searchInput = ref)}
                                    searchPlaceHolderText={searchPlaceHolderText}
                                    isDisabled={this.props.isDisabled}
                                    scenarioId={this.props.scenarioId}
                                    isSuggestionsCalloutVisible={this.isSuggestionsCalloutVisible}
                                    ariaLabel={this.props.ariaLabel}
                                />
                            </div>

                            {/* Clear search box button */}
                            {this.isClearSearchBoxButtonVisible && (
                                <div
                                    className={clearBoxButton}
                                    onClick={this.onClearSearchBoxClicked}
                                    onKeyDown={this.onClearSearchBoxClickedKeyDown}
                                    tabIndex={0}
                                    title={loc(Strings.clearSearchLabel)}
                                    {...generateDomPropertiesForAria(
                                        clearSearchBoxButtonAriaProperties
                                    )}
                                >
                                    <Icon iconName={'Clear'} styles={clearBoxIconStyles} />
                                </div>
                            )}

                            {/* Search refiners */}
                            {(isSearchBoxExpanded || isHovered) && this.props.renderRefiners && (
                                /**
                                 * This wrapper div with tabindex of -1 is required
                                 * for focus events to have relatedTarget property
                                 * be non-null (because element receiving focus
                                 * needs to be able to receive focus, which divs
                                 * cannot unless given a tabindex value) in Safari.
                                 */
                                <div tabIndex={-1}>{this.props.renderRefiners()}</div>
                            )}
                        </div>

                        {/* Search button */}
                        {isSearchBoxExpanded && (
                            <FluentButton
                                appearance="icon"
                                className={searchButton}
                                iconProps={searchButtonIconProps}
                                onClick={this.onSearchButtonClicked()}
                                aria-label={loc(SearchPlaceholderStrings.SearchPlaceholder)}
                                title={loc(Strings.searchButtonTitle)}
                            />
                        )}
                    </div>
                    {/* Anchor div required for search filters. */}
                    <div id={SEARCHBOX_ANCHOR_ID} />
                </div>
            </div>
        );
    }

    private isInActiveSearchSession = () => {
        // If searchSessionGuid has a value, user is in a search session.
        const { searchSessionGuid, isPrimingCacheSession } = getScenarioStore(
            this.props.scenarioId
        );
        if (isPrimingCacheSession) {
            // We don't want to block a new search session if we are in the middle of
            // cache priming; so we don't consider it as an active search session.
            return false;
        }
        return !!searchSessionGuid;
    };

    /**
     * Start search session when compact search button is clicked
     */
    private onCompactSearchButtonClick = () => {
        startSearchSession(
            'SearchCollapsedButton',
            false /* shouldStartSearch */,
            this.props.scenarioId
        );
    };

    /**
     * On clicking search scope picker, sets focus on SearchInput component if the Search box is empty.
     */
    public onSearchScopeSelected = async (scenarioId: SearchScenarioId) => {
        if (this.searchInput) {
            const isSearchBoxEmptyFunc = await lazyIsSearchBoxEmpty.import();
            if (isSearchBoxEmptyFunc(scenarioId)) {
                this.searchInput.setFocus();
            }
        }
    };

    /**
     * Sets focus on SearchInput component.
     */
    public focusSearchInput = () => {
        /**
         * When trying to set focus on search input while search box is
         * in compact mode, simply start a search session. The focus will get
         * put in search input on component update for expanded search box
         */
        if (
            getScenarioStore(this.props.scenarioId).showSearchBoxInCompactMode &&
            !this.isInActiveSearchSession()
        ) {
            startSearchSession('Keyboard', false /* shouldStartSearch */, this.props.scenarioId);
        } else {
            this.searchInput?.setFocus();
        }
    };

    /**
     * Click handler for the search button.
     */
    private onSearchButtonClicked = () => {
        return async (evt: React.MouseEvent<unknown>) => {
            evt.stopPropagation();

            const { scenarioId } = this.props;

            /**
             * If search box isn't empty, take action depending on whether
             * or not the user is in command mode. Otherwise, just focus the
             * search input.
             */
            const isSearchBoxEmptyFunc = await lazyIsSearchBoxEmpty.import();
            if (!isSearchBoxEmptyFunc(scenarioId)) {
                // Hide suggestions dropdown.
                setIsSuggestionsDropdownVisible(false, scenarioId);
                const store = getScenarioStore(scenarioId);

                // Special case when in the OrgExplorer app.  The first suggestion should automatically be selected
                if (
                    shouldUseAppHostSearch() &&
                    getSelectedApp() === LaunchPageAppId.OrgExplorer &&
                    !!store.currentSuggestions &&
                    store.currentSuggestions.Suggestions?.length > 0
                ) {
                    suggestionSelected(
                        store.currentSuggestions.Suggestions[0],
                        'SearchButton',
                        scenarioId,
                        store.currentSuggestions.TraceId ?? '',
                        0
                    );

                    if (!store.searchText) {
                        onSearchTextChanged('', scenarioId);
                        setSearchTextForSuggestion('', scenarioId);
                    }
                } else {
                    startSearch('SearchButton', scenarioId, true /* explicitSearch */);
                    if (isFeatureEnabled('sea-focusSearchBox')) {
                        this.searchInput?.unFocusShadow();
                    }
                }
            } else {
                this.focusSearchInput();
            }
        };
    };

    /**
     * Click handler to set focus on the SearchInput component.
     */
    private onSearchBoxClick = (evt: React.MouseEvent<unknown>) => {
        evt.stopPropagation();
        this.focusSearchInput();
    };

    /**
     * Dispatches the exitSearch action to notify consumer that user wants to exit
     * the search experience.
     */
    private onExit = (actionSource: string) => {
        if (this.props.onHoverHandlers?.onMouseLeave) {
            this.props.onHoverHandlers?.onMouseLeave();
        }
        if (isFeatureEnabled('sea-focusSearchBox')) {
            this.searchInput?.unFocusShadow();
        }
        exitSearch(actionSource, this.props.scenarioId, false /* forceExit */);
    };

    /**
     * Focus handler for when an element within the SearchBox component loses focus.
     * We check if the element receiving focus is within the SearchBox component,
     * and only dispatch the blur action if it is not.
     */
    private onSearchBoxBlur = (evt: React.FocusEvent<EventTarget>) => {
        let relatedTargetElement = evt.relatedTarget as HTMLElement;

        // Fallback for IE, which doesn't always set relatedTarget.
        if (!relatedTargetElement) {
            relatedTargetElement = document.activeElement as HTMLElement;
        }

        /**
         * A way of determining if element that caused JavaScript event is from
         * within search box.
         *
         * In case of IE, as relatedTarget is undefined we fallback to
         * activeElement.
         *
         * When user clicks on the persona card, the activeElement is the
         * LPC card's container div whose id is 'LPCPseudoTabbableElement'. The elementContains
         * function fails for this element and hence we are falling back to comparing the ID of this
         * div when determining whether the search box entirely lost focus.
         * VSO: 32596 - Search input grabs focus upon dismissing the suggestion dropdown while the
         * LPC card was open for any people suggestion
         *
         * For Safari, the relatedTargetElement is the div that the target is
         * contained within, so in the case of the search box interactions
         * execute search button, the elementContains check fails, so just check
         * if that element IS the search box container itself.
         *
         * Firefox on Mac does not focus the SEARCH_SCOPE_SWITCHER_ID button. Adding a outer div with a tabindex fixes it.
         * Hence there needs to be additional check for SEARCH_SCOPE_SWITCHER_WRAPPER_ID
         *
         * With IE, when the dropdown is clicked, the relatedTargetElement.id takes the value of SEARCH_SCOPE_SWITCHER_ID_OPTION.
         * Thus, it was added to prevent the search box from being blurred.
         */

        const searchScopeButton = document.getElementById(SEARCH_SCOPE_SWITCHER_ID);

        const isTargetElementWithinSearchBox =
            elementContains(this.searchBoxContainer, relatedTargetElement) ||
            elementContains(searchScopeButton, relatedTargetElement) ||
            relatedTargetElement.id == LPC_CARD_ID ||
            relatedTargetElement.id == SEARCH_SCOPE_SWITCHER_ID_OPTION ||
            relatedTargetElement.id == SEARCH_SCOPE_SWITCHER_ID_LIST ||
            relatedTargetElement.id === SEARCH_SCOPE_SWITCHER_ID ||
            relatedTargetElement.id === SEARCH_SCOPE_SWITCHER_WRAPPER_ID ||
            (relatedTargetElement.firstElementChild &&
                relatedTargetElement.firstElementChild.id === SEARCHBOX_ID);

        // Truly "blur" only if event is from outside of search box.
        if (!isTargetElementWithinSearchBox) {
            if (isFeatureEnabled('sea-focusSearchBox')) {
                this.searchInput?.unFocusShadow();
            }
            setIsSuggestionsDropdownVisible(false, this.props.scenarioId);
            searchBoxBlurred(this.props.scenarioId);
        }
    };

    /**
     * Click handler when user chooses to click the "X" on a pill to remove it
     * from the search box.
     */
    private onRemovePill = async (suggestionPillId: string) => {
        // Dispatch action for to remove suggestion pill from store.
        const removeSuggestionPillFromSearchBox =
            await lazyRemoveSuggestionPillFromSearchBox.import();
        removeSuggestionPillFromSearchBox(suggestionPillId, this.props.scenarioId, 'Click');
    };

    private onClearSearchBoxClicked = (
        evt: React.MouseEvent<unknown> | React.KeyboardEvent<unknown>
    ) => {
        const scenarioId = this.props.scenarioId;
        evt.stopPropagation();

        // Clear text and pills in the search box.
        clearSearchBox(scenarioId);

        // Put focus back into the search input.
        this.focusSearchInput();

        // Log datapoint that button was clicked.
        logUsage('Search_ClearSearchBoxButtonClicked', [SearchScenarioId[scenarioId]]);
    };

    /**
     * Because we have to use a div instead of a button to get the proper padding,
     * we have to manually handle the keyboard interactions. If user interacts
     * with the "clear search box" button via the keyboard (Enter or Space), then
     * call the click handler.
     */
    private onClearSearchBoxClickedKeyDown = (evt: React.KeyboardEvent<unknown>) => {
        if (evt.keyCode === KeyCodes.enter || evt.keyCode === KeyCodes.space) {
            this.onClearSearchBoxClicked(evt);
        }
    };
}

const ObserverSearchBox = observer(SearchBox);
type ObserverSearchBox = InstanceType<typeof SearchBox>;
export default ObserverSearchBox;
