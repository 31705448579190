import type { OWAConnectedAccount } from './schema/OWAConnectedAccount';

import { createStore } from 'owa-satcheljs';
import { AccountsStoreState } from './schema/AccountsStoreState';

const owaAccountsStore = createStore('OWAAccountsStore', {
    accountsMap: new Map<string, OWAConnectedAccount>([]),
    accountsLoadedState: AccountsStoreState.None,
})();

const getOWAAccountsStore = () => owaAccountsStore;
export default getOWAAccountsStore;
