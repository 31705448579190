import { createStore } from 'owa-satcheljs';
import type CopilotPrioritizeSettings from '../types/CopilotPrioritizeSettings';
import type CopilotStyleSettings from '../types/CopilotStyleSettings';
interface CopilotPrioritizeSettingsStore {
    isInitialized: boolean;
    settings: CopilotPrioritizeSettings;
}

interface CopilotStyleSettingsStore {
    isInitialized: boolean;
    settings: CopilotStyleSettings;
}

interface CopilotSettingsStoreMap {
    copilotPrioritizeSettingsStoreMap: Map<string, CopilotPrioritizeSettingsStore>;
    copilotStyleSettingsStoreMap: Map<string, CopilotStyleSettingsStore>;
}

export default createStore<CopilotSettingsStoreMap>('copilotSettingsStore', {
    copilotPrioritizeSettingsStoreMap: new Map(),
    copilotStyleSettingsStoreMap: new Map(),
});
