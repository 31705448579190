import { type MailRibbonControlId, type MailRibbonTabId } from 'owa-ribbon-ids/lib/mailRibbonId';
import { constructKeytip } from 'owa-command-ribbon';
import {
    createMailAppButton,
    createMailAppFlyoutAnchor,
    createMailAppSplitButton,
} from 'owa-mail-ribbon-utils/lib/createMailRibbonButton';
import {
    getDefaultSlrAppRibbonButtonStyles,
    getDefaultSlrSplitButtonAppRibbonButtonStyles,
} from 'owa-command-ribbon-styles';
import { onChangeNoteColorMenuClicked } from '../utils/deferredOnChangeNoteColorMenuClicked';
import { onSnoozeMenuClicked } from '../utils/deferredOnSnoozeMenuClicked';
import { onViewGroupsMenuClicked } from '../utils/deferredOnViewGroupsMenuClicked';
import { onMyDayMenuClicked } from '../utils/deferredOnMyDayMenuClicked';
import {
    onDeleteNoteClicked,
    onPinUnpinClicked,
    onRestoreClicked,
    onRestoreAllClicked,
    onRibbonCustomizerClicked,
    onUndoClicked,
    onViewEmailFromNoteClicked,
    onViewSettingsClicked,
    onRemindersClicked,
    onExpandCollapseConversationClicked,
    onSyncMailboxClicked,
    onLightRPClicked,
} from 'owa-mail-ribbon-command-actions/lib/commandActions';
import {
    openDiscoverGroups,
    openGroupsPanel,
} from 'owa-mail-ribbon-command-actions/lib/onGroupsCommandActions';
import type { ReadOnlyRibbonControlDefProps } from 'owa-mail-ribbon-utils';
import type { RibbonControlProps } from '@1js/acui-ribbon-like/lib/UISurfaces/Ribbon/Controls/RibbonControlProps';
import { owaComputedFn } from 'owa-computed-fn';
import { getComputedCallback } from 'owa-ribbon-mobx-utils/lib/getComputedCallback';
import { getControlInfo } from 'owa-mail-ribbon-utils/lib/getControlInfo';
import { getDefaultRibbonStyles } from 'owa-command-ribbon-styles/lib/util/getDefaultRibbonStyles';
import { getMenuDefinition } from '../../store/menuDefinitionStore';
import { onDensityMenuClicked } from '../utils/deferredOnDensityMenuClicked';
import { resetMenuDefinitionMutator } from '../../mutatorActions/menuDefinitionMutatorActions';
import { getGlobalSettingsAccountMailboxInfo } from 'owa-account-source-list-store';
import { getIsSearchTableShown } from 'owa-mail-list-store';
import { onFolderMenuOptionsMenuClicked } from '../utils/deferredOnFolderMenuOptionsMenuClicked';

/**
 * This file is for shared components in read-only scenarios. Meaning, any tab or control that only shows up
 * when a user is composing. In this case, Home tab, View tab, and Notes tab are good examples.
 *
 * For controls that show up in compose-only scenarios, please use composeSharedControlDefinitions.ts.
 */

export const getDeleteNoteControl = owaComputedFn(function getDeleteNoteControl(
    props: ReadOnlyRibbonControlDefProps
): RibbonControlProps {
    const controlInfo = getControlInfo(640, props);
    return createMailAppButton(
        640,
        controlInfo.text,
        controlInfo.tooltip,
        props,
        getComputedCallback(640, onDeleteNoteClicked),
        constructKeytip([6], 640),
        controlInfo.iconName,
        undefined /*iconColor*/,
        getDefaultSlrSplitButtonAppRibbonButtonStyles()
    );
});

export const getChangeNoteColorControl = owaComputedFn(function getChangeNoteColorControl(
    props: ReadOnlyRibbonControlDefProps
): RibbonControlProps {
    const controlInfo = getControlInfo(641, props);
    const storeName = 'ChangeNoteColorMenuDefinitionStore';
    return createMailAppFlyoutAnchor(
        641,
        controlInfo.text,
        controlInfo.tooltip,
        props,
        getMenuDefinition(storeName),
        constructKeytip([6], 641, true /* hasMenu */),
        controlInfo.iconName,
        undefined /*iconColor*/,
        getDefaultRibbonStyles(),
        undefined /* itemId */,
        {
            onExecute: () => {
                onChangeNoteColorMenuClicked(props);
            },
            onAfterMenuDismiss: () => {
                resetMenuDefinitionMutator(storeName);
            },
        }
    );
});

export const getNotesViewEmailControl = owaComputedFn(function getNotesViewEmailControl(
    props: ReadOnlyRibbonControlDefProps
): RibbonControlProps {
    const controlInfo = getControlInfo(642, props);
    return createMailAppButton(
        642,
        controlInfo.text,
        controlInfo.tooltip,
        props,
        getComputedCallback(642, onViewEmailFromNoteClicked),
        constructKeytip([6], 642),
        controlInfo.iconName,
        undefined /*iconColor*/,
        getDefaultRibbonStyles()
    );
});

export const getRibbonCustomizerControl = owaComputedFn(function getRibbonCustomizerControl(
    tabId: MailRibbonTabId,
    props: ReadOnlyRibbonControlDefProps
): RibbonControlProps {
    const controlInfo = getControlInfo(558, props);
    const mailboxInfo = props.mailboxInfo ?? getGlobalSettingsAccountMailboxInfo();
    return createMailAppButton(
        558,
        controlInfo.text,
        controlInfo.tooltip,
        props,
        getComputedCallback(558, onRibbonCustomizerClicked, tabId, mailboxInfo),
        constructKeytip([tabId], 558),
        undefined /* icon */,
        undefined /* iconColor */,
        getDefaultSlrAppRibbonButtonStyles()
    );
});

export const getDensityControl = owaComputedFn(function getDensityControl(
    isSLR: boolean,
    keytipTabId: MailRibbonTabId,
    props: ReadOnlyRibbonControlDefProps
): RibbonControlProps {
    const controlInfo = getControlInfo(649, props);
    const storeName = 'DensityMenuDefinitionStore';
    return createMailAppFlyoutAnchor(
        649,
        controlInfo.text,
        controlInfo.tooltip,
        props,
        getMenuDefinition(storeName),
        constructKeytip([keytipTabId], 649, true /* hasMenu */),
        controlInfo.iconName,
        undefined /* iconColor */,
        isSLR ? getDefaultSlrAppRibbonButtonStyles() : undefined,
        undefined /* itemId */,
        {
            onExecute: () => {
                onDensityMenuClicked();
            },
            onAfterMenuDismiss: () => {
                resetMenuDefinitionMutator(storeName);
            },
        }
    );
});

export const getViewSettingsControl = owaComputedFn(function getViewSettingsControl(
    isSLR: boolean,
    keytipTabId: MailRibbonTabId,
    props: ReadOnlyRibbonControlDefProps
): RibbonControlProps {
    const controlInfo = getControlInfo(643, props);
    return createMailAppButton(
        643,
        controlInfo.text,
        controlInfo.tooltip,
        props,
        getComputedCallback(643, onViewSettingsClicked, props),
        constructKeytip([keytipTabId], 643),
        controlInfo.iconName,
        undefined /* iconColor */,
        isSLR ? getDefaultSlrAppRibbonButtonStyles() : undefined
    );
});

export const getPinUnpinControl = owaComputedFn(function getPinUnpinControl(
    isSLR: boolean,
    keytipTabId: MailRibbonTabId,
    props: ReadOnlyRibbonControlDefProps
): RibbonControlProps {
    const controlInfo = getControlInfo(548, props);
    return createMailAppButton(
        548,
        controlInfo.text,
        controlInfo.tooltip,
        props,
        getComputedCallback(548, onPinUnpinClicked),
        constructKeytip([keytipTabId], 548),
        controlInfo.iconName,
        undefined /*iconColor*/,
        isSLR ? getDefaultSlrAppRibbonButtonStyles() : undefined
    );
});

export const getSnoozeControl = owaComputedFn(function getSnoozeControl(
    isSLR: boolean,
    keytipTabId: MailRibbonTabId,
    props: ReadOnlyRibbonControlDefProps
): RibbonControlProps {
    const controlInfo = getControlInfo(571, props);
    const storeName = 'SnoozeMenuDefinitionStore';
    return createMailAppFlyoutAnchor(
        571,
        controlInfo.text,
        controlInfo.tooltip,
        props,
        getMenuDefinition(storeName),
        constructKeytip([keytipTabId], 571, true /* hasMenu */),
        controlInfo.iconName,
        undefined /*iconColor*/,
        isSLR ? getDefaultSlrAppRibbonButtonStyles() : undefined,
        undefined /*itemId*/,
        {
            onExecute: () => {
                onSnoozeMenuClicked();
            },
            onAfterMenuDismiss: () => {
                resetMenuDefinitionMutator(storeName);
            },
        }
    );
});

export const getDiscoverGroupsControl = owaComputedFn(function getDiscoverGroupsControl(
    keytipTabId: MailRibbonTabId,
    props: ReadOnlyRibbonControlDefProps
): RibbonControlProps {
    const controlInfo = getControlInfo(508, props);
    return createMailAppButton(
        508,
        controlInfo.text,
        controlInfo.tooltip,
        props,
        getComputedCallback(508, openDiscoverGroups, props),
        constructKeytip([keytipTabId], 508),
        controlInfo.iconName
    );
});

export const getViewGroupsPanelControl = owaComputedFn(function getViewGroupsPanelControl(
    isSLR: boolean,
    keytipTabId: MailRibbonTabId,
    props: ReadOnlyRibbonControlDefProps
): RibbonControlProps {
    const controlInfo = getControlInfo(663, props);
    const storeName = 'ViewGroupsMenuDefinitionStore';

    return createMailAppSplitButton(
        663,
        controlInfo.text,
        controlInfo.ariaText,
        controlInfo.tooltip,
        props,
        getMenuDefinition(storeName),
        getComputedCallback(663, openGroupsPanel),
        constructKeytip([keytipTabId], 663, true /* hasMenu */),
        controlInfo.iconName,
        undefined /*iconColor*/,
        isSLR ? getDefaultSlrSplitButtonAppRibbonButtonStyles() : undefined,
        undefined /*itemId*/,
        {
            onMenuButtonExecute: () => {
                onViewGroupsMenuClicked(keytipTabId, props);
            },
            onAfterMenuDismiss: () => {
                resetMenuDefinitionMutator(storeName);
            },
        }
    );
});

export const getUndoControl = owaComputedFn(function getUndoControl(
    isSLR: boolean,
    keytipTabId: MailRibbonTabId,
    props: ReadOnlyRibbonControlDefProps
): RibbonControlProps {
    const controlInfo = getControlInfo(580, props);
    return createMailAppButton(
        580,
        controlInfo.text,
        controlInfo.tooltip,
        props,
        getComputedCallback(580, onUndoClicked),
        constructKeytip([keytipTabId], 580),
        controlInfo.iconName,
        undefined /*iconColor*/,
        isSLR ? getDefaultSlrAppRibbonButtonStyles() : undefined
    );
});

export const getRestoreControl = owaComputedFn(function getRestoreControl(
    isSLR: boolean,
    keytipTabId: MailRibbonTabId,
    props: ReadOnlyRibbonControlDefProps
): RibbonControlProps {
    const controlInfo = getControlInfo(683, props);
    return createMailAppButton(
        683,
        controlInfo.text,
        controlInfo.tooltip,
        props,
        getComputedCallback(683, onRestoreClicked),
        constructKeytip([keytipTabId], 683),
        controlInfo.iconName,
        undefined /*iconColor*/,
        isSLR ? getDefaultSlrAppRibbonButtonStyles() : undefined
    );
});

export const getRestoreAllControl = owaComputedFn(function getRestoreAllControl(
    isSLR: boolean,
    keytipTabId: MailRibbonTabId,
    props: ReadOnlyRibbonControlDefProps
): RibbonControlProps {
    const controlInfo = getControlInfo(684, props);
    return createMailAppButton(
        684,
        controlInfo.text,
        controlInfo.tooltip,
        props,
        getComputedCallback(684, onRestoreAllClicked),
        constructKeytip([keytipTabId], 684),
        controlInfo.iconName,
        undefined /*iconColor*/,
        isSLR ? getDefaultSlrAppRibbonButtonStyles() : undefined
    );
});

export const getRemindersWindowControl = owaComputedFn(function getRemindesWindowControl(
    isSLR: boolean,
    keytipTabId: MailRibbonTabId,
    props: ReadOnlyRibbonControlDefProps
): RibbonControlProps {
    const controlInfo = getControlInfo(690, props);
    return createMailAppButton(
        690,
        controlInfo.text,
        controlInfo.tooltip,
        props,
        getComputedCallback(690, onRemindersClicked),
        constructKeytip([keytipTabId], 690),
        controlInfo.iconName,
        undefined /* iconColor */,
        isSLR ? getDefaultSlrAppRibbonButtonStyles() : undefined,
        undefined /* itemId */
    );
});
export const getExpandCollapseConversationControl = owaComputedFn(
    function getExpandCollapseConversationControl(
        props: ReadOnlyRibbonControlDefProps
    ): RibbonControlProps {
        const controlInfo = getControlInfo(687, props);
        return createMailAppButton(
            687,
            controlInfo.text,
            controlInfo.tooltip,
            props,
            getComputedCallback(687, onExpandCollapseConversationClicked, props.itemId?.Id ?? ''),
            constructKeytip([3], 687, true /* hasMenu */),
            controlInfo.iconName,
            undefined /*iconColor*/,
            getDefaultRibbonStyles()
        );
    }
);

export const getLightRPControl = owaComputedFn(function getLightRPControl(
    props: ReadOnlyRibbonControlDefProps
): RibbonControlProps {
    const controlInfo = getControlInfo(720, props);
    return createMailAppButton(
        720,
        controlInfo.text,
        controlInfo.tooltip,
        props,
        getComputedCallback(720, onLightRPClicked),
        constructKeytip([3], 720),
        controlInfo.iconName,
        undefined /*iconColor*/,
        getDefaultRibbonStyles()
    );
});

export const getSyncMailboxControl = owaComputedFn(function getSyncMailboxControl(
    isSLR: boolean,
    keytipTabId: MailRibbonTabId,
    props: ReadOnlyRibbonControlDefProps
): RibbonControlProps {
    const controlInfo = getControlInfo(701, props);
    return createMailAppButton(
        701,
        controlInfo.text,
        controlInfo.tooltip,
        props,
        getComputedCallback(701, onSyncMailboxClicked),
        constructKeytip([keytipTabId], 701),
        controlInfo.iconName,
        undefined,
        isSLR ? getDefaultSlrAppRibbonButtonStyles() : undefined,
        undefined /*itemId*/,
        {
            disabled: !props.mailboxInfo || getIsSearchTableShown(),
        }
    );
});

export const getMyDayControl = owaComputedFn(function getMyDayControl(
    props: ReadOnlyRibbonControlDefProps
): RibbonControlProps {
    const controlInfo = getControlInfo(706, props);
    const storeName = 'MyDayOptionsMenuDefinitionStore';
    return createMailAppFlyoutAnchor(
        706,
        controlInfo.text,
        controlInfo.tooltip,
        props,
        getMenuDefinition(storeName),
        constructKeytip([3], 706, true /* hasMenu */),
        controlInfo.iconName,
        undefined /* iconColor */,
        undefined /* styles */,
        undefined /* itemId */,
        {
            onExecute: () => {
                onMyDayMenuClicked();
            },
            onAfterMenuDismiss: () => {
                resetMenuDefinitionMutator(storeName);
            },
        }
    );
});

export const getFolderPaneControl = owaComputedFn(function getFolderPaneControl(
    isSLR: boolean,
    props: ReadOnlyRibbonControlDefProps
): RibbonControlProps {
    const controlInfo = getControlInfo(647, props);
    const storeName = 'FolderMenuOptionsMenuDefinitionStore';
    return createMailAppFlyoutAnchor(
        647,
        controlInfo.text,
        controlInfo.tooltip,
        props,
        getMenuDefinition(storeName),
        constructKeytip([3], 647, true /* hasMenu */),
        controlInfo.iconName,
        undefined /* iconColor */,
        isSLR ? getDefaultSlrAppRibbonButtonStyles() : undefined,
        undefined /* itemId */,
        {
            onExecute: () => {
                onFolderMenuOptionsMenuClicked(props);
            },
            onAfterMenuDismiss: () => {
                resetMenuDefinitionMutator(storeName);
            },
        }
    );
});
