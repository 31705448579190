import { options_messageorganization_searchterms } from 'owa-locstrings/lib/strings/options_messageorganization_searchterms.locstring.json';
import { options_messageorganization_title } from 'owa-locstrings/lib/strings/options_messageorganization_title.locstring.json';
import type { LazyOption } from 'owa-options-core';
import { createLazyOption } from 'owa-options-core';
import { LazyModule, LazyAction } from 'owa-bundling';

const lazyModule = new LazyModule(() => import('./lazyIndex'));
const ConversationsV2Option: LazyOption = createLazyOption(
    {
        key: 'conversationsV2',
        titleStringKey: options_messageorganization_title,
        searchTermsStringKey: options_messageorganization_searchterms,
        allowedOptionKeys: ['Conversations'],
    },
    lazyModule,
    m => m.fullOption
);
export default ConversationsV2Option;

export const lazySaveConversationsV2OptionQuick = new LazyAction(
    lazyModule,
    m => m.saveConversationsOptionQuick
);
