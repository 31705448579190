import type { Capability } from 'owa-capabilities';
import type { MailboxInfo } from 'owa-client-types';
import { isMonarchMultipleAccountsEnabled } from 'owa-account-source-list';
import { isFeatureEnabled } from 'owa-feature-flags';
import { isServiceRequestSupportedForMailbox } from 'owa-service/lib/utils/isServiceRequestSupportedForMailbox';
import { isMailboxSharedOrDelegate } from 'owa-account-shared-mailbox-utils';
import getUserConfiguration from 'owa-session-store/lib/actions/getUserConfiguration';
/**
 * NOTE: Please see the README for more information on how the
 * capability functions must be implemented and used.
 *
 * This capability is being used to determine if the outlook favorites UI should be enabled
 */
export const favoritesCapability: Capability = {
    isEnabled: (_mailboxInfo?: MailboxInfo) => {
        return true; // by default
    },
    isSupported: (mailboxInfo?: MailboxInfo) => {
        if (mailboxInfo) {
            if (isMonarchMultipleAccountsEnabled()) {
                // Monarch Favorites support logic
                if (isFeatureEnabled('fp-local-folder-favorites')) {
                    //Local folder favorites are supported for shared and archive mailboxes, just not PSTs
                    return isServiceRequestSupportedForMailbox(mailboxInfo);
                } else {
                    const isShared = isMailboxSharedOrDelegate(mailboxInfo);
                    const isArchiveFolder = mailboxInfo.type === 'ArchiveMailbox';
                    // Without the isLocalFolderFavoriteSupported feature flag, no support for shared/delegate or archive mailboxes
                    return (
                        !isShared &&
                        !isArchiveFolder &&
                        isServiceRequestSupportedForMailbox(mailboxInfo)
                    );
                }
            } else {
                // OWA Favorites support logic
                return (
                    mailboxInfo?.type === 'UserMailbox' &&
                    !getUserConfiguration().SessionSettings?.IsExplicitLogon
                );
            }
        }
        return false;
    },
};
