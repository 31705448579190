import type { MailboxInfo } from 'owa-client-types';
import { isSharedCoprincipalAccountEnabled } from 'owa-anchormailbox/lib/isSharedCoprincipalAccountEnabled';
import { isMailboxSharedOrDelegateUserMailbox } from 'owa-anchormailbox/lib/isMailboxSharedOrDelegateUserMailbox';
import { getAccountScopeUserSettings } from 'owa-session-store';

/**
 * Returns true if the mailbox is shared or a delegate mailbox.
 */
export function isMailboxSharedOrDelegate(mailboxInfo: MailboxInfo): boolean {
    if (mailboxInfo.type === 'UserMailbox') {
        return isSharedCoprincipalAccountEnabled()
            ? isMailboxSharedOrDelegateUserMailbox(mailboxInfo)
            : !!getAccountScopeUserSettings(mailboxInfo).SessionSettings?.IsExplicitLogon;
    }

    return mailboxInfo.type === 'SharedMailbox';
}
