import type { NewFolderDialogStore } from './schema/NewFolderDialogStore';
import { createStore } from 'owa-satcheljs';

/**
 * Selector to get UI store for folder permissions package
 */
const store = createStore<NewFolderDialogStore>('newFolderDialogStore', {
    shouldShow: false,
})();
export default store;
export const getStore = () => store;
