import { getCoprincipalAccountForIndexerValue } from 'owa-account-source-list-store';
import { getIndexerValueForMailboxInfo, type MailboxInfo } from 'owa-client-types';
import { getAccountScopeUserSettings } from 'owa-session-store';

export function isMailboxSharedOrDelegateUserMailbox(mailboxInfo: MailboxInfo): boolean {
    if (mailboxInfo.type === 'UserMailbox') {
        // First check the MailboxInfo to see if it is marked as an explicit logon
        if (mailboxInfo.isExplicitLogon) {
            return true;
        }

        // Second check the account to see if it is marked as an additional mailbox
        const account = getCoprincipalAccountForIndexerValue(
            getIndexerValueForMailboxInfo(mailboxInfo)
        );
        if (account?.isAnotherMailbox) {
            return true;
        }

        // Finally check the session setting to see if it is marked as an explicit logon. We check
        // the SessionSettings because it is present in both OWA and Monarch
        return !!getAccountScopeUserSettings(mailboxInfo).SessionSettings?.IsExplicitLogon;
    }

    return false;
}
