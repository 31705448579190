import type { IContextualMenuItem, Point } from '@fluentui/react';
import { createStore } from 'owa-satcheljs';

interface NativeContextMenuState {
    point?: Point;
    contextualMenuItems: IContextualMenuItem[];
    showContextMenu: boolean;
}

const nativeContextMenuStateStore = createStore<NativeContextMenuState>('nativeContextMenuState', {
    point: undefined,
    contextualMenuItems: [],
    showContextMenu: false,
})();

const getNativeContextMenuStateStore = () => nativeContextMenuStateStore;
export default getNativeContextMenuStateStore;
