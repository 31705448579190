import type { DragData } from 'owa-dnd/lib/utils/dragDataUtil';
import type { MailboxInfo } from 'owa-client-types';
import {
    getIndexerValueForMailboxInfo,
    isSameCoprincipalAccountMailboxInfos,
} from 'owa-client-types';
import type MailFolderDragData from 'owa-mail-folder-store/lib/store/schema/MailFolderDragData';
import { lazyAddFavoriteFolder } from 'owa-mail-favorites-store';
import {
    getStore as getFavoritesStore,
    lazyUpdateFavoritePosition,
    isMultiAccountsCombinedFavoritesEnabled,
    isFolderInFavorites,
} from 'owa-favorites';
import type { FavoriteNodeDragData } from 'owa-favorites-types';
import { isCapabilitySupported } from 'owa-capabilities';
import { favoritesCapability } from 'owa-capabilities-definitions/lib/favoritesCapability';

export const canDropFavorite = (dragData: DragData, mailboxInfo: MailboxInfo): boolean => {
    if (isMultiAccountsCombinedFavoritesEnabled()) {
        return true;
    }
    const draggedItemData = dragData.itemData as {
        mailboxInfo: MailboxInfo;
    };
    const favoriteMailboxInfo = draggedItemData?.mailboxInfo;
    return isSameCoprincipalAccountMailboxInfos(favoriteMailboxInfo, mailboxInfo);
};

export const onDropFavorite = async (dragData: DragData, favoriteId: string) => {
    const draggedFavorite = dragData as FavoriteNodeDragData;
    const dropTargetId = favoriteId;
    const dragTargetId = draggedFavorite.favoriteId;
    const updateFavoritePosition = await lazyUpdateFavoritePosition.import();
    updateFavoritePosition(
        dragTargetId /* idToUpdate */,
        dropTargetId /* destinationFolderId */,
        draggedFavorite.favoriteType
    );
};

// We are currently not:
//  * when combined favorites is not enabled, moving favorites from one section to another is not supported
//  * we shouldn't be adding folders that are already in favorites
export const canDropFolder = (dragData: DragData, mailboxInfo: MailboxInfo): boolean => {
    // If there is no information, return false
    if (!dragData.itemData) {
        return false;
    }
    const draggedItemData = dragData.itemData as {
        treeType: string;
        mailboxInfo: MailboxInfo;
        draggedFolderId: string;
    };

    if (!isCapabilitySupported(favoritesCapability, draggedItemData.mailboxInfo)) {
        return false;
    }

    // We should not be re-adding folders to favorites that are already there
    if (isFolderInFavorites(draggedItemData.draggedFolderId)) {
        return false;
    }

    if (isMultiAccountsCombinedFavoritesEnabled()) {
        return true;
    } else {
        // if combined favorites is not on, we should only be adding favorites that match the mailbox
        return isSameCoprincipalAccountMailboxInfos(draggedItemData.mailboxInfo, mailboxInfo);
    }
};

export const onDropFolder = async (
    dragData: DragData,
    mailboxInfo: MailboxInfo,
    favoriteId: string
) => {
    const mailFolderItemBeingDragged = dragData as MailFolderDragData;
    const draggedFolderId = mailFolderItemBeingDragged.folderId;
    const dropTargetId = favoriteId;
    let newIndex = undefined;
    if (isMultiAccountsCombinedFavoritesEnabled()) {
        newIndex = getFavoritesStore().orderedCombinedOutlookFavoritesIds.indexOf(dropTargetId);
    } else {
        const key = getIndexerValueForMailboxInfo(mailboxInfo);
        newIndex = getFavoritesStore()
            .favoriteTreeData.get(key)
            ?.orderedOutlookFavoritesIds.indexOf(dropTargetId);
    }

    const addFavoriteFolder = await lazyAddFavoriteFolder.import();
    addFavoriteFolder(draggedFolderId, 'Drag', newIndex);
};
