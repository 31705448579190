import { getTableViewFromTableQuery } from 'owa-mail-triage-table-utils';
import {
    getFocusedInboxTableQuery,
    getOtherInboxTableQuery,
} from 'owa-mail-triage-table-utils/lib/getDefaultInboxTableQueries';
import onInitialTableLoadComplete from './onInitialTableLoadComplete';
import type { PerformanceDatapoint } from 'owa-analytics';
import { lazyLoadSearchTable } from 'owa-mail-execute-search-actions';
import loadInitialGroupTable from 'owa-mail-list-actions/lib/actions/table/loadInitialGroupTable';
import loadInitialMailTable from 'owa-mail-list-actions/lib/actions/table/loadInitialMailTable';
import loadInitialGroupFolderTable from 'owa-mail-list-actions/lib/actions/table/loadInitialGroupFolderTable';
import onLoadInitialRowsSucceeded from 'owa-mail-list-actions/lib/actions/table/onLoadInitialRowsSucceeded';
import getSelectedTableView from 'owa-mail-list-store/lib/utils/getSelectedTableView';
import type { ActionSource } from 'owa-mail-store';
import MailListItemSelectionSource from 'owa-mail-store/lib/store/schema/MailListItemSelectionSource';
import getPersonaPhotoQueue from 'owa-persona/lib/actions/getPersonaPhotoQueue';
import { Module } from 'owa-workloads';
import { getCurrentModule } from 'owa-app-module-store';
import { action, orchestrator, mutatorAction } from 'satcheljs';
import type { TableQuery, TableView } from 'owa-mail-list-store';
import {
    getListViewState,
    type TableQueryType,
    getFocusedFilterForTable,
} from 'owa-mail-list-store';
import { onAfterTableLoad } from '../helpers/onAfterTableLoad';
import { resetSelection } from 'owa-mail-actions/lib/mailListSelectionActions';
import type { SessionData } from 'owa-service/lib/types/SessionData';
import type { SubstrateAnswerResponse, SubstrateAnswerRequest } from '@1js/search-hostapp-owa';
import { getMailboxInfo } from 'owa-mail-mailboxinfo';
import { setModuleContextMailboxInfo } from 'owa-module-context-mailboxinfo';
import { getFolderChangeDigestAction } from 'owa-mail-focused-inbox-rollup-store';
import type FocusedViewFilter from 'owa-service/lib/contract/FocusedViewFilter';
import { lazyExecuteGroupSearch } from 'owa-groups-execute-search-actions';
import { SearchProvider } from 'owa-search-service/lib/data/schema/SearchProvider';
import type { SearchTableQuery } from 'owa-mail-list-search';
import { lazyLoadLocalMailSearchTable } from 'owa-mail-local-search';
import onAccountSwitched from 'owa-mail-account-actions/lib/actions/onAccountSwitched';
import { isSameCoprincipalAccountMailboxInfos } from 'owa-client-types';
import { isFeatureEnabled } from 'owa-feature-flags';

const setSelectedTableView = action(
    'SET_SELECTED_TABLEVIEW',
    (tableview: TableView, moduleContext?: Module) => ({
        tableview,
        moduleContext,
    })
);

const handleFocusedOtherTables = action('HANDLE_SELECTED_TABLEVIEW', (newTableView: TableView) => ({
    newTableView,
}));

/**
 * Load the table from the table query
 * @param tableQuery the table query
 * @param loadTableViewDatapoint - performance datapoint that starts when the user initiates the table load
 * @param actionSource action that initiated the switch folder action
 * @param onAnswerResponseReceived is a callback that is called when single call for answer and serp is enabled, it passes the 3s response to the answers component
 * @param substrateAnswerRequest has the answersEntities for for the single call for answer and serp
 * @param substrateAnswersClientFlights has the client flight list for substrateAnswers
 * @return a promise that resolves when the load table from server has completed
 */
export default function loadTableViewFromTableQuery(
    tableQuery: TableQuery,
    loadTableViewDatapoint?: PerformanceDatapoint,
    actionSource?: ActionSource,
    initialSessionData?: SessionData,
    onAnswerResponseReceived?: (value: SubstrateAnswerResponse) => void,
    substrateAnswerRequest?: SubstrateAnswerRequest,
    substrateAnswersClientFlights?: string,
    moduleContext?: Module
): Promise<void> {
    const oldTableView = getSelectedTableView(moduleContext);
    const newTableView = getTableViewFromTableQuery(tableQuery);
    // Use the reference from the store (http://aka.ms/mobx4)
    tableQuery = newTableView.tableQuery;
    // 1. Set the current selected table in list view store to the new table
    setSelectedTableView(newTableView, moduleContext);
    const oldMailBox = getMailboxInfo(oldTableView);
    const currentMailBox = getMailboxInfo(newTableView);
    if (!isSameCoprincipalAccountMailboxInfos(oldMailBox, currentMailBox)) {
        onAccountSwitched(oldMailBox, currentMailBox);
    }
    // Store selectedRowKeys for old table before clearing the selection
    let selectedRowKeysForOldTable: string[] = [];

    // 2. Call onBeforeTableLoad before loading new table
    if (oldTableView) {
        // oldTableView is null when first loading OWA as there is no previously selected table
        selectedRowKeysForOldTable = [...oldTableView.selectedRowKeys.keys()];
        // always clear the getPersonaPhotoQueue when switching the table
        getPersonaPhotoQueue.clear();

        if (!isFeatureEnabled('tri-preserve-ml-selection')) {
            resetSelection(
                oldTableView,
                MailListItemSelectionSource.Reset,
                actionSource == 'FolderNodeClick'
            );
        }
    }

    // 3. Load New Table
    let loadTablePromise: Promise<void>;
    switch (tableQuery.type) {
        case 1:
            const searchTableQuery = tableQuery as SearchTableQuery;
            if (moduleContext === Module.Groups) {
                loadTablePromise = lazyExecuteGroupSearch.importAndExecute(
                    newTableView,
                    actionSource ?? '',
                    onInitialTableLoadComplete,
                    loadTableViewDatapoint
                );
            } else if (
                searchTableQuery.searchProvider === SearchProvider.Offline ||
                searchTableQuery.searchProvider === SearchProvider.PST
            ) {
                loadTablePromise = lazyLoadLocalMailSearchTable.importAndExecute(
                    newTableView,
                    onInitialTableLoadComplete
                );
            } else {
                loadTablePromise = lazyLoadSearchTable.importAndExecute(
                    newTableView,
                    onInitialTableLoadComplete,
                    onLoadInitialRowsSucceeded,
                    actionSource ?? '',
                    loadTableViewDatapoint,
                    undefined /*state*/,
                    onAnswerResponseReceived,
                    substrateAnswerRequest,
                    substrateAnswersClientFlights
                );
            }
            break;
        case 2:
            loadTablePromise = loadInitialGroupTable(
                newTableView,
                onInitialTableLoadComplete,
                actionSource ?? null,
                moduleContext
            );
            break;
        case 3:
            loadTablePromise = loadInitialGroupFolderTable(
                newTableView,
                onInitialTableLoadComplete,
                actionSource ?? null,
                moduleContext
            );
            break;
        case 0:
            loadTablePromise = loadInitialMailTable(
                newTableView,
                onInitialTableLoadComplete,
                actionSource ?? null,
                initialSessionData
            );
            break;
        default:
            throw new Error('UnsupportedTableQueryType');
    }

    // 4. Call onAfterTableLoad after loading new table
    if (oldTableView) {
        onAfterTableLoad(selectedRowKeysForOldTable, oldTableView, newTableView);
    }

    handleFocusedOtherTables(newTableView);

    return loadTablePromise;
}

/* eslint-disable-next-line owa-custom-rules/require-add-identifier-to-mutator-action-variables -- (https://aka.ms/OWALintWiki)
 * Mutator action variables should end with 'Mutator' so that we can more easily identify potential misuses of it.
 *	> Please add 'Mutator' substring add the end of the mutator action variable name. */
const setSelectedTableViewId = mutatorAction(
    'setSelectedTableViewId',
    (id: string, moduleContext?: Module) => {
        getListViewState(moduleContext).selectedTableViewId = id;
    }
);

orchestrator(setSelectedTableView, actionMessage => {
    const tableView = actionMessage.tableview;
    setSelectedTableViewId(tableView.id, actionMessage.moduleContext);
    if (tableView.tableQuery.type !== 1) {
        const currentModule = actionMessage.moduleContext ?? getCurrentModule() ?? Module.Mail;
        setModuleContextMailboxInfo(currentModule, getMailboxInfo(tableView));
    }
});

orchestrator(handleFocusedOtherTables, actionMessage => {
    const { newTableView } = actionMessage;

    const focusedViewFilter = getFocusedFilterForTable(newTableView);
    // If not a focused/other tableview, then return
    if (focusedViewFilter === -1) {
        return;
    }

    const newTableMailboxInfo = getMailboxInfo(newTableView);

    // Update on folder selection. Next updates are coming through notification. See: updateRollupOnAddOrUpdateRowNotification
    const tableQueryForFocusedOther =
        focusedViewFilter === 0
            ? getOtherInboxTableQuery(newTableMailboxInfo)
            : getFocusedInboxTableQuery(newTableMailboxInfo);
    getFolderChangeDigestAction(tableQueryForFocusedOther);
});
