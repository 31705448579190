import { createLazyComponent, LazyModule, registerLazyOrchestrator } from 'owa-bundling';
import { GlobalSettingsAccountFolderNodeWithDummyList } from './components/GlobalSettingsAccountFolderNodeWithDummyList';
import onAccountAuthSucceeded from 'owa-account-source-list-store/lib/actions/onAccountAuthSucceeded';
import { configureNewPstAccount } from 'owa-pst-file-config/lib/actions/configureNewPstAccount';
import { remediatePendingPstAccount } from 'owa-pst-file-config/lib/actions/remediatePendingPstAccount';
import { remediatePstAccount } from 'owa-pst-file-config/lib/actions/remediatePstAccount';
import { showAccountConfigurationDialog } from 'owa-account-config/lib/actions/showAccountConfigurationDialog';

const lazyModule = new LazyModule(() => import(/* webpackChunkName: "Accounts" */ './lazyIndex'));

export const AccountsTreeView = createLazyComponent(
    lazyModule,
    m => m.AccountsTreeView,
    GlobalSettingsAccountFolderNodeWithDummyList
);

// Lazy orchestrators
registerLazyOrchestrator(
    onAccountAuthSucceeded,
    lazyModule,
    m => m.onAccountAuthSucceededOrchestrator
);

registerLazyOrchestrator(
    configureNewPstAccount,
    lazyModule,
    m => m.configureNewPstAccountOrchestrator
);
registerLazyOrchestrator(
    remediatePendingPstAccount,
    lazyModule,
    m => m.remediatePendingPstAccountOrchestrator
);
registerLazyOrchestrator(remediatePstAccount, lazyModule, m => m.remediatePstAccountOrchestrator);
registerLazyOrchestrator(
    showAccountConfigurationDialog,
    lazyModule,
    m => m.addNewAccountOrchestrator
);
